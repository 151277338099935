

  const txeLibRdx = {

    addTx(state, action) {  

        var tt = [...state.entries,action.payload.entry];
        state.entries=tt;
    },
    pullIndex(state, action) {
            var tt = [...state.entries.slice(0,action.payload.index),...state.entries.slice(action.payload.index+1)];
      state.entries=tt;
  },
  clear(state, action) {
    if(action.payload.count===2){
        state.entries=[];
    }
},
    addUnits(state, action) {
      state.units= action.payload.units ;
  },
  btnClick(state,action){
    state.btnClick=action.payload.id;
  },
  editTx(state,action){
    state.editTx=action.payload.id;
  }
}

export default txeLibRdx