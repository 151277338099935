

  const txLibRdx = {

    addUnitTxData(state, action) {
      const entry = {};
      var tempIds = [];
      entry[action.payload.id]={...action.payload.data};
      if(Object.keys(state.items).length>0){
        var temp = {...state.items,...entry};
        state.items =temp;
        tempIds=[...state.ids];
        if(!tempIds.includes(action.payload.id))
        {
          tempIds.push(action.payload.id);
        }
        state.ids =tempIds;
      }
      else
      {
        state.ids=[action.payload.id];
        state.items = entry;
      }
    }
}

export default txLibRdx