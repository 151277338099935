import { CHE } from "./SVGAlpha";


export   default function   SVGLogo  ({height=40}) 
{
  const fillE = "#2b3990";
  const fillC = "#9E4835";
  const fillH = "#414042";  
   
  return    <div className={`h-[${height}] w-[${height}]`} >  <CHE fill={[fillC,fillH,fillE]} height={height} /></div>
 
    
  
}
 