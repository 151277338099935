import { createSlice } from "@reduxjs/toolkit"; 
import txLibInit from './init'
import txLibRdx from './rdx'
import fetchTxs from "./fetch";

 const txLibSlice = createSlice({
  name: "txs",
  initialState:txLibInit,
  reducers: {
    ...txLibRdx
  } ,
  extraReducers: (builder) => {
        builder.addCase(fetchTxs.pending, (state) => {
          state.status = 'loading';
        });
        builder.addCase(fetchTxs.fulfilled, (state, action) => {
          state.status='done';
          state.items = action.payload.items;
          state.ids = action.payload.ids;
          state.indexes = action.payload.indexes;

        });
        builder.addCase(fetchTxs.rejected, (state, action) => {
          state.status = 'error';
          state.error = action.error.message;
        });
      },
});

export default txLibSlice.reducer;
