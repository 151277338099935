import React from 'react'

const Mobile = ({className}) => {
  return (
    <div className={className}>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="32" viewBox="0 0 24 24" strokeWidth="1" stroke="#2c3e50" fill="none" strokeLinecap="round" strokeLinejoin="round">



  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <path d="M6 5a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v14a2 2 0 0 1 -2 2h-8a2 2 0 0 1 -2 -2v-14z" />
  <path d="M11 4h2" />
  <path d="M12 17v.01" />


        </svg></div>
    )


}

export default Mobile

