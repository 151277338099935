import { configureStore } from "@reduxjs/toolkit"; 
import uoSlice from "./slices/uoSlice/uoSlice";
import txoLibSlice from "./slices/txOpts/slice";
import unitsSlice from "./slices/unitSlice/unitsSlice";
import uiSlice from "./slices/uiSlice/uiSlice";
import txLibSlice  from "./slices/txLibSlice/slice";
import txeLibSlice  from "./slices/txEntry/slice";
import txRibSlice  from "./slices/txRib/slice";
import txTicketSlice from "./slices/txTicket/slice"
 const Store = configureStore({
  reducer: { 
    uo: uoSlice,
    ui:uiSlice,
    txo: txoLibSlice,
    units:unitsSlice,
    txs:txLibSlice,
    txe:txeLibSlice,
    txRib:txRibSlice,
    txTicket:txTicketSlice
  },
});

export default Store