import { createAction } from "@reduxjs/toolkit";

const txRibActs = {
  setUnitID() {
    return createAction("txRib/setUnitID", function prepare(uID) {
      return { payload: { id: uID } };
    });
  },
  setShadowGUI() {
    return (
      createAction("txRib/setShadowGUI",
      function prepare(data) {
        return { payload: { data: data } }}))}
      ,
  setMonths() {
    return createAction("txRib/setMonths", function prepare(rawMonths, uID) {
      return { payload: { months: rawMonths, uID: uID } };
    });
  },
  addPayment() {
    return createAction("txRib/addPayTx", function prepare(tx) {
      return { payload: { tx: tx } };
    });
  },
  addCharge() {
    return createAction("txRib/addChgTx", function prepare(tx) {
      return { payload: { tx: tx } };
    });
  },
  addBounce() {
    return createAction("txRib/addBounceTx", function prepare(tx) {
      return { payload: { tx: tx } };
    });
  },
  guiSetValues() {
    return createAction("txRib/guiSetValues", function prepare(data) {
      return { payload: { data: data } };
    });
  },
  guiClear() {
    return createAction("txRib/guiClear", function prepare() {
      return {};
    });
  },
  guiTxs() {
    return createAction("txRib/addTxs", function prepare(txs) {
      return { payload: { txs: txs } };
    });
  },
  guiInit() {
    return createAction("txRib/setInitGUI", function prepare() {
      return {};
    });
  },
};

export default txRibActs;
