import React, {   useContext, useEffect, useState } from 'react'
import EChange from '../../../../store/statics/EChange'; 
import { useDispatch, useSelector } from 'react-redux';
import UnitTblActions from '../../../../store/slices/uoSlice/uoFxs';

const NameBox = ({index}) => {
  const dispatch = useDispatch();

  const [unitNum,setUnitNum] = useState(" ");

  
 const name = useSelector((state) => state.units.items[index].shadow.name);
 const id = useSelector((state) => state.units.items[index].id);
 const eChanged = useSelector((state) => state.units.items[index].eChanged); 

  const reportRentChange=(e)=>{
    var screen = e.target.value.match(/^\s{0,}(?<a>\d{0,2})(?<dot>\.){0,1}(?<b>\d{0,2}){0,1}\s{0,}/);

    var a = (screen.groups?.a)?screen.groups.a:'';
    var dot = (screen.groups?.dot)?screen.groups.dot:'';
    var b = (screen.groups?.b)?screen.groups.b:'';
    setUnitNum(()=>{return  a+dot+b;})
  }

   

  const reportRentChanged=(e)=>{
    var screen = e.target.value.match(/^\s{0,}(?<a>\d{1,2})(?<dot>\.){1}(?<b>\d{1,2})\s{0,}/);
    if(screen!==null){
    var a = screen.groups?.a;
    var b = screen.groups?.b;
    if(a&&b){
      if(a.length<2){a="0"+a;}
      if(b.length<2){b="0"+b;}

        var tt = {};
        tt['name']= a+"."+b;
        var eC =  eChanged;

        eC|=EChange.name;

        if(tt['name']=== name){
        eC^=EChange.name;}
          setUnitNum(()=>{return tt.name})
          dispatch(UnitTblActions.editPartAction()(id,index,tt,eC))
    // updateShadow(id,tt,EChange.name,index,orgName===tt[name]);
    }
    else
    {

        setUnitNum(()=>{return  name})
    }

  }
    else
    {

      setUnitNum(()=>{return name})

    }
  }

  useEffect(()=>{

    setUnitNum(()=>{return name;})

  },[name])

  return (
    < >
        <input type='text' value={unitNum}   className="w-20 text-center bg-tabasco-200/40" onChange={(e)=> {reportRentChange(e)}} onBlur ={(e)=> {reportRentChanged(e)}} />
    </ >
  )
}

export default NameBox