import React from "react";
import TableRow from "./TableRow";
import { useSelector } from "react-redux";

const TableRowsFocus = () => {
  const focusIndex = useSelector((state) => state.uo.focus.index);

  if (focusIndex === -1) {
    return <></>;
  }
  return (
    <div className="flex flex-col shrink-0 grow">
      <TableRow index={focusIndex} />
    </div>
  );
};

export default TableRowsFocus;
