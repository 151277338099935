import React, { useContext, useEffect, useState } from "react";

import EChange from "../../../../store/statics/EChange";
import { useDispatch, useSelector } from "react-redux";
import UnitTblActions from "../../../../store/slices/uoSlice/uoFxs";
const OccupantBox = ({ index }) => {
  const [occupantStr, setOccupant] = useState("-");

  const occupant = useSelector((state) => state.units.items[index].shadow.occupant);
  const id = useSelector((state) => state.units.items[index].id);
  const eChanged = useSelector((state) => state.units.items[index].eChanged);
  const colWidth = useSelector((state) => state.uo.col.widthsInputs.occupant);
  const dispatch = useDispatch();
  const reportOccupantChange = (e) => {
    var v = e.target.value;
    if (v.length === 0) {
      v = " ";
    }
    setOccupant(() => {
      return v;
    });
  };
  const reportOccupantChanged = (e) => {
    var tt = {};
    tt["occupant"] = e.target.value;

    var eC = eChanged;

    eC |= EChange.occupant;

    if (tt["occupant"] ===  occupant) {
      eC ^= EChange.occupant;
    }
    setOccupant(() => {
      return tt.occupant;
    });
    dispatch(UnitTblActions.editPartAction()(id, index, tt, eC))
  };

 
  useEffect(() => {
    setOccupant(()=>{return occupant})
  }, [occupant]);

  return (
    <>
      <input
        type="text"
        value={occupantStr}
        className={`   ${colWidth}  bg-tabasco-200/40`}
        onChange={(e) => {
          reportOccupantChange(e);
        }}
        onBlur={(e) => {
          reportOccupantChanged(e);
        }}
      />
    </>
  );
};

export default OccupantBox;
