const uoInit = {
  col: {
    pref: null,
    order: [],
    odds: [],
    widths:[],
    widthsInputs:[],
    titles:[],
    justData:[],
  },
  row: {
    odds: [],
  },
  edit:{id:'',index:-1},
  focus:{id:'',index:-1},
  active:1,
  drag:{
    col:-1,
    buoy:-1,
    buoyName:"",
    dragName:"",
    started:false,
    ended:false,
  },
  pending:false,
  fulfilled:false,
  error:""
};
export default uoInit;
