function CopyAsArray(tree){
    const root =[];

    for(let i=0;i<tree.length;i++){
       root.push(DeepCopyTree(tree[i]));
    }
    return root;
}

function CopyAsObject(tree){
    const root = {};
    const keys = Object.keys(tree);
    keys.forEach((k,i)=>{
      root[k]=DeepCopyTree(tree[k]);
    })
    return root;
}



function DeepCopyTree(tree,desiredType="array"){
        if(tree===undefined){
            if(desiredType==="array"){return [];}
            else if (desiredType==="object"){return {};}
            return undefined;
        }
        if(Array.isArray(tree)){
            return CopyAsArray(tree);
        }
        else if(typeof tree === "object" && tree !== null ){
            return CopyAsObject(tree);
        }
        else
        {
            return tree;
        }
}
export default DeepCopyTree