function itemBranch(keyName = "ids", branchName = "branch") {
  const temp = {};
  temp[branchName] = {};
  temp[keyName] = [];
  return temp;
}

export function typeStruct() {
  return {
    roster: itemBranch("mIDs", "months"),
    delta: {},
    ids: [],
  };
}
function getMonthPart() {
  return { ids: [], num: [], children: {},xRef:{} };
}
function txRibInit() {
  return {
    unitID: "",
    txs:{},
    shadowGUI:{},
    months: getMonthPart(),
    draggingID: "",
    tickets: itemBranch('txIDs','ticket'),

    all: itemBranch("mIDs", "months"),
    startBal: typeStruct(),
    payments: typeStruct(),
    charges: typeStruct(),
    bounces: typeStruct(),
    gui:{location:{},directory:{},amounts:{},xRefAmounts:{},values:{},valueIDs:[]},

  };
}

export default txRibInit;
