import { createSlice } from "@reduxjs/toolkit"; 
import txeLibInit from './init'
import txeLibRdx from './rdx' 

 const txeLibSlice = createSlice({
  name: "txe",
  initialState:txeLibInit,
  reducers: {
    ...txeLibRdx
  } ,
  extraReducers: (builder) => {
        
      },
});

export default txeLibSlice.reducer;
