import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Ticket from "./rows/Ticket";
import { RndKey } from "../../functions/RndKey";
import DnD from "./DnD/App";

import App from "../../DnDTest/App";
const TxTableSet = ({ device }) => {
  const bg = useSelector((state) => state.ui.bg.unitTable.back);
  const focusID = useSelector((state) => state.uo.focus.id);

  const unitCount = useSelector((state) => state.units.items.length);
  const maxHeight = (unitCount - 2) * 32;

  const theTicketInfo = useSelector((state) => state.txRib.tickets.ticket);
  const ticketKeys = useSelector((state) => state.txRib.tickets.txIDs);
  const months = useSelector((state) => state.txRib?.months.ids);
  const monthsXRef = useSelector((state) => state.txRib?.months.children);

  const focusIndex = useSelector((state) => state.uo.focus.index);

  const hasMonths = months?.length > 0;

  function getDesktop() {
    return (
      <div className="flex flex-col justify-start">
        <div
          className={`flex flex-row justify-end m-0 p-0   `}
          style={{ height: maxHeight + "px" }}
        >
          <div className={`${bg} flex flex-col  `}>
            <div className="flex flex-col overflow-y-scroll snap-y w-180 max-w-180 scrollbar-thumb-woodybrown-200 scrollbar-thin scrollbar-track-merlin-900">
            {/* <App ></App> */}

              <DnD />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return getDesktop()

};

export default TxTableSet;
