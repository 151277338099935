import { collection, doc, getDoc, getDocs, orderBy, query, setDoc, updateDoc } from "firebase/firestore";
import { db } from "../../auth/fbase";


export function getRowOddsDict(data) {
  const oddsDict = [];
  var zActive = 0;
  var zInactive = 0;
  var zTrash = 0;
  Object.keys(data).forEach((k, i) => {

    var act = data[k].shadow.active;

    oddsDict.push({
      active: (act === 1) ? zActive++ : -1,
      inactive: (act === 2) ? zInactive++ : -1,
      trash: (act === 0) ? zTrash++ : -1,
      all: i
    });
  })
  return oddsDict;
}

const GetOrderedDict = (data) => {
  const theKeys = Object.keys(data);
  const theValues = [];
  theKeys.forEach((k) => { theValues.push(data[k].index) });
  const theValues2 = [...theValues];
  theValues2.sort();
  const theKDict = {};
  const theKK = [];
  theValues2.forEach((v) => theKDict[v] = theKK.push(theKeys[theValues.indexOf(v)]));
  return theKK;
}




const GetOddsDict = (orderedArray, rawDict) => {
  const showingDict = [];

  orderedArray.forEach((k) => { if (rawDict[k].show) { showingDict.push(k) } });
  return showingDict;
}




export async function GetUnitTx(id) {

  const q = query(collection(db, "units", id, "account"),orderBy('m'));
  const querySnapshot = await getDocs(q);
  var txs = [];
  querySnapshot.forEach((doc) => {
    if (doc.exists()) {
      const dD = doc.data();
      const iD = { id: doc.id };
      const nD = { ...dD, ...iD }
      txs.push({ dataTx: { ...nD },   eChangedTx: 0 });
    }
  });

  return txs;
}
export async function GetUnitTxsComboUData(doc, getTxs = false) {
  const nD = doc.data();
  if (getTxs) {
    const data = await GetUnitTx(doc.id);
    return { id: doc.id, data: nD, shadow: { ...nD }, eChanged: 0, txs: data, txLen: data.length }
  }
  else {
    return { id: doc.id, data: nD, shadow: { ...nD }, eChanged: 0, txs: [], txLen: 0 }
  }
}


export async function GetUnitData() {

  var units = [];
  const q = query(collection(db, "units"), orderBy('order'));

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => { units.push(GetUnitTxsComboUData(doc)) })
  return Promise.all(units);
}


export function WriteUnitDataToCloud(uData) {
  updateDoc(doc(db, "units", uData.id), uData).then(() => { })
}

