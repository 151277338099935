import React, { useContext, useEffect } from "react"; 
import { AsMoneyShort } from "../../../../functions/AsMoney";
import { useSelector } from "react-redux";

const EndRBal = ({ index }) => { 

  const id = useSelector((state) => state.units.items[index].id);
  const endRBal = useSelector(
    (state) => state.units.items[index].shadow.endRBal
  );

  const colWidth = useSelector((state) => state.uo.col.widths.endRBal);
  const justCol = useSelector((state) => state.uo.col.justData.endRBal);
  const edit = useSelector((state) => state.uo.edit.id);

  const h = useSelector((state) => state.ui.h);

  useEffect(() => {}, []);

  return (
    <div
      className={`flex-none relative ${justCol} shrink ${h.line} text-right mr-2 ${ endRBal} ${colWidth} `}
    > 
        {AsMoneyShort(endRBal, true, 9)}
    </div>
  );
};

export default EndRBal;
