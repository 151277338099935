import EChange from "../../statics/EChange";



  const UORdx = {

    focus(state, action) {
        state.focus = {...action.payload};
    },
    edit(state, action) {
        state.edit = {...action.payload};
    },
    active(state, action) {
        state.row.odds = action.payload.odds;
        state.active = action.payload.value;

    },
    dragCol(state, action) {
        state.active = action.payload.value;
    },
    updateCol(state,action){
        state.col  ={...action.payload.col};
    },
    setDragCol(state,action){
      //  console.log('Drag col set: '+ action.payload.col + "  " + action.payload.name);
        state.drag.buoy=-1;
        state.drag.ended=false;
        state.drag.buoyName="";
        state.drag.col=action.payload.col;
        state.drag.dragName=action.payload.name;
        state.drag.started=true;
    },
    setDragBuoy(state,action){
        //console.log('Drag buoy set: '+ action.payload.col + "  " + action.payload.name);
        state.drag.buoy=action.payload.col;
        state.drag.buoyName=action.payload.name;
    },
    setDragEnded(state,action){
     //   console.log('Drag ended set: '+ action.payload.col + "  " + action.payload.name);
        state.drag.ended=true;
        state.drag.buoyName=action.payload.name;
    },
    resetDrag(state){
       // console.log('Drag reset');
        state.drag.col=-1;
        state.drag.buoy=-1;
        state.drag.buoyName="";
        state.drag.dragName="";
        state.drag.started=false;
        state.drag.ended=false;
    }
    // fetch(state, action) {
    //     var index = action.payload.index;
    //     if (index > -1 && (action.payload.id === state.units[index].id)) {
    //         if (action.payload.remove === false) {
    //             var shadow = { ...state.units[index].shadow };
    //             state.units[index].shadow = { ...shadow, ...action.payload.data };
    //             state.units[index].eChanged = action.payload.eChanged;
    //         }
    //         else if (action.payload.remove === true) {
    //             var shadow2 = { ...state.units[index].shadow };
    //             state.units[index].shadow = { ...shadow2, ...action.payload.data };
    //             var ee = state.units[index].eChanged
    //             state.units[index].eChanged = ((ee | action.payload.eChanged) ^ action.payload.eChanged);
    //         }
    //     }
    // },
    // mergeShadowData(state, action) {
    //     var index = action.payload.index;
    //     if (index > -1 && (action.payload.id === state.units[index].id)) {
    //         var shadow = { ...state.units[index].shadow };
    //         var data = { ...state.units[index].data };
    //         var merged = { ...data, ...shadow };
    //         state.units[index].data = { ...merged };
    //         state.units[index].shadow = { ...merged };
    //         state.units[index].eChanged = EChange.none;
    //     }
    // },
    // resetShadowData(state, action) {
    //     var index = action.payload.index;
    //     if (index > -1 && (action.payload.id === state.units[index].id)) {
    //         var shadow = { ...state.units[index].data };
    //         state.units[index].shadow = shadow;
    //         state.units[index].eChanged = EChange.none;
    //     }
    // }
}

export default UORdx