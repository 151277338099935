import { createSlice } from "@reduxjs/toolkit";  
import uiInit from "./uiInit";


export const uiSlice = createSlice({
  name: "uo",
  initialState:uiInit,
  reducers: { 
  } ,
  extraReducers: (builder) => {
   
      },
});

export default uiSlice.reducer;
