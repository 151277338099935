
import {   useDraggable, useDroppable } from "@dnd-kit/core";
import { DollarText } from "../../../functions/DollarText";
import { useDispatch, useSelector } from "react-redux";

function DragOverDraggable(props){
  const { isOver,setNodeRef } = useDroppable({
    id: props.id,
  });
  return (
    <div   className={`absolute w-76 h-8 ${isOver&&'bg-sunshade-400/50'} `}
      ref={setNodeRef}>
      </div>
  )
}


export function DraggableTicket(props) {
  const dispatch = useDispatch();
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: props.id,
  });
  const txID = props.id.substring(0,props.id.length-4);
  const mID  = useSelector((state) => state.txTicket.txMonthXRef[txID][0]);
  const txMonth  = useSelector((state) => state.txTicket.txMonthXRef[mID]);
  const typeNum = props.bounce?3:(props.type==="pay"?1:props.type==='chg'?2:0);
  const txOldSpot  = useSelector((state) => state.txTicket.txOrgMonth[txID]);
  const txNewSpot  = useSelector((state) => state.txTicket.txNewMonth[txID]);
  const oldMonth = txOldSpot?txOldSpot.mID:undefined;
  const newMonth = txNewSpot?txNewSpot.mID:undefined;
  const oldIndex = oldMonth?(typeNum===1?txOldSpot.payIndex:(typeNum===2?txOldSpot.chgIndex:typeNum===3?txOldSpot.bIndex:-1)):-2;
  const newIndex = newMonth?(typeNum===1?txNewSpot.payIndex:(typeNum===2?txNewSpot.chgIndex:typeNum===3?txNewSpot.bIndex:-1)):-2;
  const sameMonth = oldMonth?oldMonth===newMonth:false;
  const sameIndex = oldIndex===newIndex;
  const type = props.type;
  const bounce = props.bounce;
  let  rowIndex = 0;
  const doesOnClick = ()=>{
    console.log('hi');

  }
  if(type==='pay'){
    rowIndex = txMonth.pay.indexOf(txID);
  }
  else if (type==='chg'){
    rowIndex = txMonth.chg.indexOf(txID);
  }
  let oddRow = rowIndex%2===1;
  const style = transform
    ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
      }
    : undefined;
  return (
    <button
      ref={setNodeRef}
      style={style}
      {...listeners}
      {...attributes}
      className={`${
        props.type === "pay" ? "bg-denim-950" : "bg-lotus-400"
      } {flyStart?'z-45':'z-40'}   h-8`}
    >
      {" "}
      <div className={`flex w-76 h-8 ml ${(!sameMonth||!sameIndex)?(oddRow?'bg-merlin-600':'bg-merlin-500'):'bg-merlin-800'}`}>

        <div   className="relative w-76 h-8">
        <DragOverDraggable id={props.id}/>
          <div
            className={`flex w-76 h-8 ${
              props.type !== "pay" ? "flex-row-reverse" : "flex-row"
            }  `}
          >

            <div
              className={`flex-none h-8 w-44 ${
                props.bounce?"bg-sunshade-400":props.type === "pay"
                    ? (oddRow?"bg-bilbao-800":"bg-bilbao-700")
                  : (oddRow?"bg-lotus-600":"bg-lotus-500")
              }`}
            >
              {props.rNum}
            </div>
            <div
              className={`flex-none h-8 w-32 text-right ${
                props.bounce?"bg-sunshade-300":  props.type === "pay"
                ? (oddRow?"bg-bilbao-800/90":"bg-bilbao-700/90")
                : (oddRow?"bg-lotus-600/90":"bg-lotus-500/90")
              } whitespace-pre`}
            >
              {props.amt==='b'?'bounced  ':DollarText({ value: props.amt, digits: 11, cents: true })}
            </div>
          </div>

        </div>
      </div>
    </button>
  );
}
