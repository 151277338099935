import { createSlice } from "@reduxjs/toolkit";    
import unitsInit from "./unitsInit";
import { fetchUnits } from "./unitFetchRdx";
import { UnitsRdx } from "./unitsRdx";
export const unitsSlice = createSlice({
  name: "units",
  initialState:unitsInit,
  reducers: { ...UnitsRdx
  } ,
  extraReducers: (builder) => {
        builder.addCase(fetchUnits.pending, (state) => {
          state.pending = true;
        });
        builder.addCase(fetchUnits.fulfilled, (state, action) => {
          state.pending = false;
          state.fulfilled=true;
          state.items = action.payload
        });
        builder.addCase(fetchUnits.rejected, (state, action) => {
          state.isLoading = false;
          state.error = action.error.message;
        });
      },
});

export default unitsSlice.reducer;
