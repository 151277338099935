import { RndKey } from "../../../functions/RndKey";
import DeepCopyTree from "../../cloudFx/DeepCopyTree";
import txTicketInit from "./init";

function handleMonthXRef(monthIDs, monthNums) {
  let mm = {};
  monthIDs.forEach((m, i) => {
    mm[m] = monthNums[i];
    mm[monthNums[i]] = m;
  });
  return mm;
}
function doMove(state,fromMonthIndex,toMonthIndex,ticketID,ticketTypeNum,toIndex){


    const fromMonthID = state.monthIDs[fromMonthIndex];
    const toMonthID = state.monthIDs[toMonthIndex];
    const toMonthNum = state.monthXRef[toMonthID];
    const fromMonthNum = state.monthXRef[fromMonthID];

    if (toMonthNum === fromMonthNum) {
      let theRange =
        ticketTypeNum === 1
          ? [...state.txMonthXRef[toMonthID].pay]
          : [...state.txMonthXRef[toMonthID].chg];
      let fromIndex = theRange.indexOf(ticketID);
      if (toIndex === fromIndex) {
        return;
      }
      let len = theRange.length;
      if (fromIndex === 0 && toIndex === 1) {
        theRange = [theRange[1], ticketID, ...theRange.slice(2)];
      } else if (toIndex === len - 1 && fromIndex === toIndex - 1) {
        theRange = [
          ...theRange.slice(0, fromIndex),
          theRange[toIndex],
          ticketID,
        ];
      } else {
        theRange = [
          ...theRange.slice(0, fromIndex),
          ...theRange.slice(fromIndex + 1),
        ];
        if (fromIndex < toIndex) {
          theRange = [
            ...theRange.slice(0, toIndex - 1),
            ticketID,
            ...theRange.slice(toIndex - 1),
          ];
        } else if (toIndex < fromIndex) {
          theRange = [
            ...theRange.slice(0, toIndex),
            ticketID,
            ...theRange.slice(toIndex),
          ];
        }
      }
      state.txMonthXRef[toMonthID][ticketTypeNum === 1 ? "pay" : "chg"] =
        theRange;
        reSumMonths(state, fromMonthIndex, fromMonthIndex)
    } else {
      const updateTxMonthXRefTx = [toMonthID, ticketTypeNum];

      let updateTxMonthXRefMonth =
        ticketTypeNum === 1
          ? [...state.txMonthXRef[toMonthID].pay]
          : [...state.txMonthXRef[toMonthID].chg];
      let updateTxMonthXRefMonthRemove =
        ticketTypeNum === 1
          ? [...state.txMonthXRef[fromMonthID].pay]
          : [...state.txMonthXRef[fromMonthID].chg];
      let pullIndex = updateTxMonthXRefMonthRemove.indexOf(ticketID);
      updateTxMonthXRefMonthRemove = [
        ...updateTxMonthXRefMonthRemove.slice(0, pullIndex),
        ...updateTxMonthXRefMonthRemove.slice(pullIndex + 1),
      ];
      updateTxMonthXRefMonth = [
        ...updateTxMonthXRefMonth.slice(0, toIndex + 1),
        ticketID,
        ...updateTxMonthXRefMonth.slice(toIndex + 1),
      ];

      state.txMonthXRef[toMonthID][ticketTypeNum === 1 ? "pay" : "chg"] =
        updateTxMonthXRefMonth;
      state.txMonthXRef[fromMonthID][ticketTypeNum === 1 ? "pay" : "chg"] =
        updateTxMonthXRefMonthRemove;
      state.txData[ticketID]["m"] = toMonthNum;

      state.txMonthXRef[ticketID] = updateTxMonthXRefTx;
      reSumMonths(
        state,
        Math.min(fromMonthIndex, toMonthIndex),
        Math.max(fromMonthIndex, toMonthIndex)
      );
    }
}
function reSumMonths(state, indexStart, indexEnd) {
  var monthIDs = [...state.monthIDs.slice(indexStart, indexEnd + 1)];

  let startRun = state.txMonthXRef[monthIDs[0]].startBal;

  monthIDs.forEach((mID) => {
    state.txMonthXRef[mID].startBal = startRun;
    var dupNewMonth = {...state.txNewMonth};
    let paySum = 0;
    let chgSum = 0;
    state.txMonthXRef[mID].pay.forEach((p) => {
      dupNewMonth[p]={...dupNewMonth[p],mID:mID,payIndex: state.txMonthXRef[mID].pay.indexOf(p)};

      paySum += state.txData[p].paySum;
    });
    state.txMonthXRef[mID].chg.forEach((p) => {
      if (state.txData[p].bounced) {
        chgSum += state.txData[p].chg?.b;
        dupNewMonth[p]={...dupNewMonth[p],mID:mID,bIndex: state.txMonthXRef[mID].chg.indexOf(p)};
      } else {
        chgSum += state.txData[p].chgSum;
        dupNewMonth[p]={...dupNewMonth[p],mID:mID,chgIndex: state.txMonthXRef[mID].chg.indexOf(p)};
      }
    });
    state.txMonthXRef[mID].paySum = paySum;
    state.txMonthXRef[mID].chgSum = chgSum;
    state.txMonthXRef[mID].subBal = paySum - chgSum;
    let endTemp = startRun + paySum - chgSum;
    state.txMonthXRef[mID].endBal = endTemp;
    state.txNewMonth={...dupNewMonth};
    startRun = endTemp;
  });
}
const txTicketRdx = {
  setTxMonthIDs(state, action) {
    var monthIDs = [...action.payload.monthIDs];
    var monthNums = [...action.payload.monthNums];
    var txs = { ...action.payload.txs };
    state.txData = DeepCopyTree(txs);
    var txIDs = [];
    var txMonthXRef = {};
    state.monthIDs = monthIDs;
    state.monthXRef = handleMonthXRef(monthIDs, monthNums);
    let keys = [];
    Object.keys(txs).forEach((theTx, i) => {
      txIDs.push(theTx);
      let tx = txs[theTx];
      let txMonthID = state.monthXRef[tx.m];
      txMonthXRef[tx.id] = [txMonthID, tx.both];
      var dupOrgMonth = {...state.txOrgMonth};
      var dupNewMonth = {...state.txNewMonth};

      let clean = false;
      if (!keys.includes(txMonthID)) {
        keys.push(txMonthID);
        txMonthXRef[txMonthID] = {
          pay: [],
          chg: [],
          paySum: 0,
          chgSum: 0,
          subBal: 0,
          startBal: 0,
          endBal: 0,
        };
        clean = true;
      }
      if ((tx.both & 1) === 1) {
        let payTemp = clean
          ? [tx.id]
          : [...txMonthXRef[txMonthID]["pay"], tx.id];
          dupOrgMonth[tx.id]={...dupOrgMonth[tx.id],mID:txMonthID,payIndex:payTemp.length-1,type:tx.both};
          dupNewMonth[tx.id]={... dupNewMonth[tx.id],mID:txMonthID,payIndex:payTemp.length-1,type:tx.both};
        txMonthXRef[txMonthID]["pay"] = payTemp;
        txMonthXRef[txMonthID]["paySum"] =
          txMonthXRef[txMonthID]["paySum"] + tx.paySum;
        txMonthXRef[txMonthID]["subBal"] =
          txMonthXRef[txMonthID]["subBal"] + tx.paySum;
      }
      if ((tx.both & 2) === 2) {
        let chgTemp = clean
          ? [tx.id]
          : [...txMonthXRef[txMonthID]["chg"], tx.id];
        txMonthXRef[txMonthID]["chg"] = chgTemp;
        if(tx.bounced){
          txMonthXRef[txMonthID]["chgSum"] =
          txMonthXRef[txMonthID]["chgSum"] + tx.chg?.f;
        txMonthXRef[txMonthID]["subBal"] =
          txMonthXRef[txMonthID]["subBal"] - tx.chg?.f;
          dupOrgMonth[tx.id]={...dupOrgMonth[tx.id],mID:txMonthID,bIndex:chgTemp.length-1,type:tx.both};
          dupNewMonth[tx.id]={... dupNewMonth[tx.id],mID:txMonthID,bIndex:chgTemp.length-1,type:tx.both};
        }else{
        txMonthXRef[txMonthID]["chgSum"] =
          txMonthXRef[txMonthID]["chgSum"] + tx.chgSum;
        txMonthXRef[txMonthID]["subBal"] =
          txMonthXRef[txMonthID]["subBal"] - tx.chgSum;
          dupOrgMonth[tx.id]={...dupOrgMonth[tx.id],mID:txMonthID,chgIndex:chgTemp.length-1,type:tx.both};
          dupNewMonth[tx.id]={... dupNewMonth[tx.id],mID:txMonthID,chgIndex:chgTemp.length-1,type:tx.both};

        }}
      state.txOrgMonth={...dupOrgMonth};
      state.txNewMonth={...dupNewMonth};
    });

    state.txIDs = txIDs;
    let startBal = 0;
    monthIDs.forEach((mID) => {
      txMonthXRef[mID]["startBal"] = startBal;
      startBal += txMonthXRef[mID]["subBal"];
      txMonthXRef[mID]["endBal"] = startBal;
    });
    state.txMonthXRef = txMonthXRef;
  },
  moveTx(state, action) {
    const fromMonthIndex = action.payload.fromMonthIndex;
    const toMonthIndex = action.payload.toMonthIndex;
    const ticketID = action.payload.ticketID;
    const ticketTypeNum = action.payload.ticketTypeNum;
    const toIndex = action.payload.toIndex;
    doMove(state,fromMonthIndex,toMonthIndex,ticketID,ticketTypeNum,toIndex);
  },
  restore(state,action){
    var txID = action.payload.ticketID;
    var txNewMonth = state.txNewMonth[txID];
    var txOrgMonth = state.txOrgMonth[txID];
    const fromMonthIndex = state.monthIDs.indexOf(txNewMonth.mID);
    const toMonthIndex = state.monthIDs.indexOf(txOrgMonth.mID);
    const  ticketTypeNum = txNewMonth.type;

    const toIndex = (ticketTypeNum===3)?(txOrgMonth.bIndex):((ticketTypeNum===1)?(txOrgMonth.payIndex):((ticketTypeNum===2)?txOrgMonth.chgIndex:0));

    doMove(state,fromMonthIndex,toMonthIndex,txID,ticketTypeNum,toIndex);
  },
};

export default txTicketRdx;
