import React, { createContext, useEffect, useRef } from "react";

import DeepCopyTree from "../../../store/cloudFx/DeepCopyTree";
import { DroppableMonths } from "./DroppableBig";
import { DroppableBooths } from "./Droppable";
import { DndContext } from "@dnd-kit/core";
import { useDispatch, useSelector } from "react-redux";
import txRibActs from "../../../store/slices/txRib/acts";
import { RndKey } from "../../../functions/RndKey";
import txTicketActs from "../../../store/slices/txTicket/acts";
import { DroppableAddTx } from "./DroppableAddTx";

export const BrainCtx = createContext();
function stripDataLocus(data){
  const {locus,...rest}=data;
  return rest;
}
function sumMonth(data, mID) {
  var monthData = data["txMonthXRef"][mID];
  var payZ = 0;
  var chgZ = 0;
  monthData.forEach((d) => {
    var t = data.txs[d];
    payZ += t.paySum;
    chgZ += t.chgSum;
  });
  return [payZ, chgZ, payZ - chgZ];
}

function processAmountSumStartEnd(data ) {
  var runBal = 0;
  var newBook = {};
  data["monthIDs"].forEach((mID) => {
    let ttlMonth = sumMonth(data, mID);
    let subBal = ttlMonth[2];
    let startRun = runBal;
    runBal += subBal;
    let endBal = runBal;
    var bb = [...ttlMonth, startRun, endBal];
    console.log(bb)
    newBook[mID] = [...bb];
  });
  return newBook;
}

export function Brain({
 focusID,
  locus,
}) {

  const data = useSelector((state) => state.txTicket);

  const dispatch = useDispatch();


  function trimID(id){
    return id.substring(0,id.length-4);
  }

  const handleDragEnd=(e)=>{
    if(e.over===null){return;}
    let dragID = trimID(e.active.id);
    let overID = trimID(e.over.id);
    console.log(e.over.id );
    if(e.over.id.substring(e.over.id.length-4)==='copy'){
      console.log('copying');

    }    return;
    let fromMonth = data.txMonthXRef[dragID][0];
    let overIsMonth = data.monthIDs.includes(overID);
    let overMonth = overIsMonth?overID:data.txMonthXRef[overID][0];
    let overType = overIsMonth?0:data.txMonthXRef[overID][1];
    let overIndex = overIsMonth?0:overType===1?data.txMonthXRef[overMonth].pay.indexOf(overID):overType===2?data.txMonthXRef[overMonth].chg.indexOf(overID):0;


    let dragType = e.active.id.substring(e.active.id.length-3);
    dragType = (dragType==='pay')?1:2;
    let toMonthIndex = data.monthIDs.indexOf(overMonth);
    let fromMonthIndex = data.monthIDs.indexOf(fromMonth);
    dispatch(txTicketActs.moveTx()({fromMonthIndex:fromMonthIndex,toMonthIndex:toMonthIndex,ticketID:dragID,ticketTypeNum:dragType,toIndex:overIndex}))


  }

  useEffect(() => {


  }, [ ]);
  if (data.done === 0) {
    return <></>;
  }
  return (
    <BrainCtx.Provider value={{ data }}>
      <DndContext onDragEnd={handleDragEnd}>
        {data.monthIDs.map((b, i) => {
          return (
            <DroppableMonths id={b + "-big"} odd={i % 2 === 1} key={RndKey()}>
              <div className="flex flex-row justify-between w-177">

                <DroppableAddTx id={b+"copy"} />

                <DroppableBooths id={b + "-pay"} isPay={true}>
                  {data.txMonthXRef[b].pay.map(
                    (k) =>locus[k].pay
                  )}
                </DroppableBooths>

                <div className="flex w-20 shrink-0 grow-0" />
                <DroppableBooths id={b + "-chg"} isPay={false}>
                  {data.txMonthXRef[b].chg.map(
                       (k) =>locus[k].chg
                  )}
                </DroppableBooths>
                <div className="flex w-12 grow" />
              </div>
            </DroppableMonths>
          );
        })}
      </DndContext>
    </BrainCtx.Provider>
  );


}
