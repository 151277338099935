import { createAction } from "@reduxjs/toolkit";
import { WriteUnitDataToCloud } from "../../cloudFx/UnitDb";






/**
 * @description This provides the Actions to use
 * for settings on the UnitTable
 */
const UnitTblActions = {


  /**
 * @description This is used to set the Active level using
 * @param {number} a - This is the level 0 dead, 1 active, 2 asleep, 3 all
 * @param {Array} odds -This is the array of the odd indexed rows under the new a
 */
  activeAction(a, oddRows) {
    return createAction('uo/active', function prepare(a,oddRows ) { return { payload: { value: a, odds:oddRows  } } });
  },
  /**
* @description This is used to set the unit that is being edited
* @param {string} id - This is the unit's id corresponding to the Firebase Doc ID
* @param {number} index - This is the unit's index in the list of all units (not just the filtered ones)
*/
  editAction(id, index) {
    return createAction('uo/edit', function prepare(id, index) { return { payload: { id: id, index: index } } });
  },
  /**
 * @description This is used to set the unit as the one under focus
 * @param {string} id - This is the unit's id corresponding to the Firebase Doc ID
 * @param {number} index - This is the unit's index in the list of all units (not just the filtered ones)
 */
  focusAction(id, index) {
    return createAction('uo/focus', function prepare(id, index) { return { payload: { id: id, index: index } } });
  },
  /**
 * @description This is used to set the Column that is in Drag mode
 * @param {number} index - This is the column index for the column that has entered drag mode.
 */
  dragColAction(index) {
    return createAction('uo/dragCol', function prepare(index) { return { payload: { index: index } } });
  },
  /**
* @description This is used to reset the shadow data in that Row
* @param {number} index - This is the row index for the row to reset.
*/
  resetAction(id, index) {
    return createAction('units/resetShadowData', function prepare(id, index) { return { payload: { id: id, index: index } } })
  },
  /**
 * @description This is used to overwrite the doc to Firestore with the shadow data in that Row
* @param {string} id - This is the unit's id corresponding to the Firebase Doc ID
* @param {number} index - This is the unit's index in the list of all units (not just the filtered ones)
* @param {Object} data - This is the data in that row
* @param {EChanged} eChanged - This contains the flags of what was changed in that row
*/
  saveAction(id, index, data, eChanged) {
    return createAction('units/writeData', function prepare(id, index, data, eChanged) { WriteUnitDataToCloud(data); return { payload: { id: id, index: index, data: data, eChanged: eChanged } } });
  },
  /**
 * @description This is used to update the Shadowd with the data provided
* @param {string} id - This is the unit's id corresponding to the Firebase Doc ID
* @param {number} index - This is the unit's index in the list of all units (not just the filtered ones)
* @param {Object} data - This is the data that changed in that row
* @param {EChanged} eChanged - This contains the flags of what was changed in that row
*/
  editPartAction(id, index, data, eChanged) {
    return createAction('units/setDataPart', function prepare(id, index, data, eChanged) { return { payload: { id: id, index: index, data: data, eChanged: eChanged } } });
  },
  /**
 * @description This is used to move a column in the UnitTable from one spot to another
* @param {string} dragName - This is the unit's id corresponding to the Firebase Doc ID
* @param {string} hoverName - This is the unit's index in the list of all units (not just the filtered ones)
*/


}

export default UnitTblActions