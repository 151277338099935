import React from "react";
import { useDroppable } from "@dnd-kit/core";



function DragOverDraggable(props){
  const { isOver,setNodeRef } = useDroppable({
    id: props.id,
  });
  return (
    <div   className={`absolute w-4 h-8 ${isOver&&'bg-sunshade-400/50'} `}
      ref={setNodeRef}>+
      </div>
  )
}


export function DroppableAddTx(props) {




  return (
    <button

      className={`${
         "bg-denim-950"
      }   z-40  w-4  mr-8  h-8`}
    >
      {" "}
      <div className={`flex w-4 h-8 left-0    bg-merlin-800  `}>

        <div   className="relative w-4 h-8   ">
        <DragOverDraggable id={props.id }/>


      </div>
      </div>
    </button>
  );
}
