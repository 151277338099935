import { USDollar } from "./AsMoney";

function cleanNumber(value){
  var rawValue=Math.abs(value);
  rawValue*=100;
  rawValue=Math.floor(rawValue);
  return rawValue;
}

/**
 * @description This will format a number as a currency string.
 * @param {number} value This is the numeric value
 * @param {number} digits This is the character width, default is 10, includes the decimal and dollar and parenthesis.
 * @param {boolean} cents Whether added spacing is added for cents when it's an even dollar, default is false.
 * @param {boolean} zeroCents Whether '.00' is added for an even dollar, default is false.
 * @param {boolean} dollar Whether dollar sign is used, default is true.
 * @param {boolean} zeroNotDash Whether zero value is used instead of a dash, default is false.
 * @param {boolean} blankIfZero Whether return value is blank for zero, default is false.
 * @param {boolean} useParen Whether spaces for parenthesis, default is true.
 * @returns
 */
export function DollarText({
  value,
  cents = false,
  digits = 16,
  blankIfZero = false,
  zeroCents = false,
  dollar = true,
  zeroNotDash = false,
  useParen=true,
}) {

  var rawValue = value===undefined?0:value;
  var isNeg = rawValue<0;
  rawValue = cleanNumber(rawValue);
  var dollarVal =Math.floor(rawValue/100);
  var centsVal= rawValue-(dollarVal*100);

  if(rawValue===0){

    if(blankIfZero){
      return ( (dollar?"$":"")+" ".padStart(digits-(dollar?1:0)-(useParen?1:0)-(cents?2:0)-1,' '))
    }
    else if (zeroNotDash){
      var temp = ( (dollar?"$":" ")+" ".padStart(digits-(dollar?1:0)-(useParen?1:0)-(cents?2:0)-1,' '))
      return temp.slice(0,-5)+"—"+temp.slice(-4);
    }
    else
    {
     return (useParen?" ":'')+(dollar?"$":"")+"0.".padStart(digits-(dollar?1:0)-(useParen?2:3)-(cents||zeroCents?2:0)-4,' ')+(zeroCents?'00':'')+(useParen?" ":'')

    }
  }

  var valueStr = dollarVal.toString();


  if(dollarVal>=1000){valueStr=valueStr.slice(0,-3)+","+valueStr.slice(-3)}
  valueStr+=".";


  if(cents||zeroCents){
    if(centsVal>0){
      var centsTemp = centsVal+"";
      centsTemp=centsTemp.padStart('0',2);
      valueStr+=centsTemp+(useParen?' ':'');
    }else
    {
      if(zeroCents){
        valueStr+="00"+(useParen?' ':'');
      }
      else if(cents){
        valueStr+="  "+(useParen?' ':'');;
       }
    }
  }
 valueStr= (dollar?" $":"")+valueStr.padStart(digits-(dollar?1:0)-(useParen?2:3)-(cents||zeroCents?2:0)-1,'\u0020')

  if(isNeg)
  {
    if(useParen){
      return "(" + valueStr.substring(1,valueStr.length-1)  + ")"
    }
    else
    {
      return "—"+valueStr.substring(1,valueStr.length ) ;
    }
  }

  return valueStr;

}
