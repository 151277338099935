import { Menu } from "@headlessui/react";
import {
  ArrowPathIcon,
  LightBulbIcon,
  PlusIcon,
  UserPlusIcon,
} from "@heroicons/react/20/solid";
import { RndKey } from "../../../functions/RndKey";
import MenuItemPart from "./MenuItem";
import { UIFxCtx } from "../../../store/slices/uiSlice/uiFx";
import { useDispatch, useSelector } from "react-redux";
import { createAction } from "@reduxjs/toolkit";
import DeepCopyTree from "../../../store/cloudFx/DeepCopyTree";
import {
  DefaultColumnPrefsDict,
  WriteUpdateColData,
} from "../../../store/slices/uoSlice/uoRdxs/uoFetchRdx";
import { useEffect } from "react";
import UpdateRent from "../../../store/actions/UpdateRent";

const HideBtn = ({ name }) => {
  const pref = useSelector((state) => state.uo.col.pref);
  const bg = useSelector((state) => state.ui.bg.menu);
  const allTitles = useSelector((state) => state.uo.col.titles);
  const capNames = () =>
    Object.keys(allTitles).sort((a, b) => {
      return pref[a].index - pref[b].index;
    });
  const allNames = () =>
    Object.keys(pref).sort((a, b) => {
      return pref[a].index - pref[b].index;
    });
  const titles = () =>
    capNames().map((n) =>
      n === "name" || n === "active" ? undefined : allTitles[n]
    );
  const filterNames = () =>
    allNames().map((n) => (n === "name" || n === "active" ? undefined : n));
  const index = useSelector((state) => state.uo.col.pref[name].index);
  const menuTitles = () => {
    const tt = titles();
    return [tt[index], ...tt.slice(index + 1), ...tt.slice(0, index)];
  };
  const menuNames = () => {
    const tt = filterNames();
    return [tt[index], ...tt.slice(index + 1), ...tt.slice(0, index)];
  };
  const dispatch = useDispatch();

  const combo = () => {
    const tt = menuTitles();
    const uu = menuNames();

    var qq = [];
    for (var i = 0; i < uu.length; i++) {
      qq.push({ title: tt[i], raw: uu[i] });
    }
    return qq;
  };

  const title = useSelector((state) => state.uo.col.titles[name]);

  function updateColumnPrefs(pf) {
    return createAction("uo/fetch/fulfilled", function prepare(pf) {
      return { payload: pf };
    });
  }

  const menuItems = ["Reset All", "Show All", "Add Unit"];
  const menuIcons = [
    <ArrowPathIcon className="w-4 h-4 text-bilbao-700" />,
    <LightBulbIcon className="w-4 h-4 text-meteor-400" />,
    <UserPlusIcon className="w-4 h-4 text-denim-900" />,
  ];

  const dragIndex = useSelector((state) => state.uo.drag.col);
  const dropIndex = useSelector((state) => state.uo.drag.buoy);
  const dragStart = useSelector((state) => state.uo.drag.started);
  const dragEnd = useSelector((state) => state.uo.drag.ended);

  const dragName = useSelector((state) => state.uo.drag.dragName);
  const dropName = useSelector((state) => state.uo.drag.buoyName);

  function updateColumnPrefs(pf) {
    return createAction("uo/fetch/fulfilled", function prepare(pf) {
      return { payload: pf };
    });
  }
  function notifyDragStart(colIndex, colName) {
    var act = createAction(
      "uo/setDragCol",
      function prepare(colIndex, colName) {
        return { payload: { col: colIndex, name: colName } };
      }
    );
    dispatch(act(colIndex, colName));
  }
  function notifyDragOver(colIndex, colName) {
    var act = createAction(
      "uo/setDragBuoy",
      function prepare(colIndex, colName) {
        return { payload: { col: colIndex, name: colName } };
      }
    );
    dispatch(act(colIndex, colName));
  }

  function notifyDragCancel() {
    var act = createAction("uo/resetDrag");
    dispatch(act());
  }
  const doUpdatePref = (pf) => {
    const extraPref = WriteUpdateColData(pf);
    dispatch(updateColumnPrefs()(extraPref));
  };

  const handleMoveColumns = (nameMoving, delta) => {
    const copyPref = DeepCopyTree(pref);
    var moveIndex = pref[nameMoving].index;
    var buoyIndex = moveIndex + delta;
    buoyIndex += 8;
    buoyIndex %= 8;
    var keys = Object.keys(copyPref);

    if (moveIndex < buoyIndex) {
      // console.log(`Moving ${nameMoving} @index:${moveIndex} to after ${nameBuoy} @index:${buoyIndex}`);
      keys.forEach((k) => {
        if (
          copyPref[k].index > moveIndex &&
          copyPref[k].index < buoyIndex + 1
        ) {
          copyPref[k].index = copyPref[k].index - 1;
        }
      });
      copyPref[nameMoving].index = buoyIndex;
      //   console.log(copyPref);
    } else {
      // console.log(`Moving ${nameMoving} @index:${moveIndex} to before ${nameBuoy} @index:${buoyIndex}`);
      keys.forEach((k) => {
        if (
          copyPref[k].index < moveIndex &&
          copyPref[k].index > buoyIndex - 1
        ) {
          copyPref[k].index = copyPref[k].index + 1;
        }
      });
      copyPref[nameMoving].index = buoyIndex;
    }
    doUpdatePref(copyPref);
  };

  const addUnit = () => {};

  const resetTheColumns = () => {
    const copyPref = DefaultColumnPrefsDict();
    const extraPref = WriteUpdateColData(copyPref);
    dispatch(updateColumnPrefs()(extraPref));
  };

  const showAllColumns = () => {
    const copyPref = DeepCopyTree(pref);

    var keys = Object.keys(copyPref);
    keys.forEach((k) => {
      copyPref[k].show = true;
    });

    const extraPref = WriteUpdateColData(copyPref);
    dispatch(updateColumnPrefs()(extraPref));
  };

  const handleClickName = (k) => {
    if (k === 0) {
      resetTheColumns();
    } else if (k === 1) {
      showAllColumns();
    } else if (k === 2) {
      addUnit();
    }
  };
  const handleRegularName = (k) => {
    const copyPref = DeepCopyTree(pref);

    copyPref[k].show = !copyPref[k].show;

    const extraPref = WriteUpdateColData(copyPref);
    dispatch(updateColumnPrefs()(extraPref));
  };
  useEffect(() => {}, [index]);

  return (
    <div className="flex ">
      <Menu as="div">
        <Menu.Button className="flex rounded-md outline-none justify-items-center z-1 ">
          <div className="z-10 justify-center w-fit h-fit ">{title}</div>
        </Menu.Button>
        <Menu.Items
          className={`fixed translate-x-7 -translate-y-7  rounded-md shadow-xl outline-none z-45 shadow-merlin-800 ${bg}  ring-2   ring-merlin-300 h-fit  `}
        >
          {name === "name" ? (
            <div className={`flex flex-col justify-around px-1 py-1  z-31   `}>
              {menuItems.map(
                (k, i) =>
                  k !== undefined && (
                    <MenuItemPart
                      colName={"p"}
                      handleClickA={handleClickName}
                      keyItem={k}
                      key={RndKey()}
                      addDivider={i === 0 ? 2 : i === 2 ? 3 : 0}
                      isMain={i === 0}
                      i={i}
                      rawName={i}
                      icon={menuIcons[i]}
                    />
                  )
              )}
              <MenuItemPart
                colName={"p"}
                handleClickA={() => {
                  handleMoveColumns(name, -1);
                }}
                handleClickB={() => {
                  handleMoveColumns(name, 1);
                }}

                keyItem={"PS"}
                key={RndKey()}
                addDivider={1}
                i={3}
                rawName={"PSs"}
                isMain={false}
                isArrows={true}
              />
            </div>
          ) : name === "rent" ? (
            <div className={`flex flex-col justify-around px-1 py-1  z-31   `}>
              {combo().map(
                (k, i) =>
                  k.title !== undefined && (
                    <MenuItemPart
                      colName={name}
                      handleClickA={handleRegularName}
                      keyItem={k.title}
                      key={RndKey()}
                      addDivider={i === 0 ? 2 : 0}
                      i={i}
                      rawName={k.raw}
                      isMain={i === 0}
                    />
                  )
              )}
              <MenuItemPart
                colName={"p"}
                handleClickA={() => {
                  handleMoveColumns(name, -1);
                }}
                handleClickB={() => {
                  handleMoveColumns(name, 1);
                }}  handleClickRent={()=>
                  UpdateRent()
              }
                keyItem={"PS"}
                key={RndKey()}
                addDivider={1}
                i={3}
                isRent={true}
                rawName={"PSs"}
                isMain={false}
                isArrows={true}
              />
            </div>
          ) : (
            <div className={`flex flex-col justify-around px-1 py-1  z-31   `}>
              {combo().map(
                (k, i) =>
                  k.title !== undefined && (
                    <MenuItemPart
                      colName={name}
                      handleClickA={handleRegularName}
                      keyItem={k.title}
                      key={RndKey()}
                      addDivider={i === 0 ? 2 : 0}
                      i={i}
                      rawName={k.raw}
                      isMain={i === 0}
                    />
                  )
              )}
              <MenuItemPart
                colName={name}
                handleClickA={() => {
                  handleMoveColumns(name, -1);
                }}
                handleClickB={() => {
                  handleMoveColumns(name, 1);
                }}
                keyItem={"PS"}
                key={RndKey()}
                addDivider={1}
                i={-1}
                rawName={"PSs"}
                isMain={false}
                isArrows={true}
              />
            </div>
          )}
        </Menu.Items>
      </Menu>
    </div>
  );
};

export default HideBtn;
