import { LightBulbIcon, LockClosedIcon } from "@heroicons/react/20/solid";
import { useDispatch, useSelector } from "react-redux";
import EChange from "../../../../store/statics/EChange";
import UnitTblActions from "../../../../store/slices/uoSlice/uoFxs";
const ActiveToggle = ({ index }) => {
  const dispatch = useDispatch();
  const uActive = useSelector((state) => state.units.items[index].shadow.active);
  const uOrgActive = useSelector((state) => state.units.items[index].data.active);
  const id = useSelector((state) => state.units.items[index].id);
  const eChanged = useSelector((state) => state.units.items[index].eChanged);
  const editID = useSelector((state) => state.uo.edit.id );
  const isEditing=()=>id===editID;
  const activeState = useSelector((state) => state.uo.active );
  const isActiveAll=()=>activeState===3;
  const iconSize = useSelector((state) => state.ui.iconSize);



  const handleClick = () => {

    if (isEditing() && isActiveAll()) {
      var tt = {};
      tt['active'] = ((uActive + 1) % 3);
      var eC = eChanged;
      eC |= EChange.active;
      if (tt['active'] === uOrgActive) {
        eC ^= EChange.active;
      }
      dispatch(UnitTblActions.editPartAction()(id, index, tt, eC));
    }
  }


  return (<div className={`flex ${iconSize}  transition-all`} onClick={() => { handleClick(); }}>
    {getIcon(uActive,  isEditing(),isActiveAll())}
  </div>)
};

export default ActiveToggle





const getLock = (active) => {
  if (active === 0) {
    return <LockClosedIcon className="text-cod-gray-950 " />;
  } else if (active === 1) {
    return <LockClosedIcon className="text-sunshade-600" />;
  } else if (active === 2) {
    return <LockClosedIcon className="text-pelorous-500 " />;
  } else {
    return <LockClosedIcon className="text-au-chico-950" />;
  }
}

const getIcon = (active, editing,activeAll) => {
  if (!activeAll&&editing) { return getLock(active) }
  if (active === 0) {
    return <LightBulbIcon className="text-cod-gray-950 " />;
  } else if (active === 1) {
    return <LightBulbIcon className="text-sunshade-500" />;
  } else if (active === 2) {
    return <LightBulbIcon className="text-pelorous-400 " />;
  } else {
    return <LightBulbIcon className="text-au-chico-900" />;
  }
}