import React, { useContext, useEffect } from "react";

import { Menu } from "@headlessui/react";
import {} from "@headlessui/react";
import SVGLogo from "../../assets/SVGLogo";
import { AuthCtx } from "../../auth/Context";
import { GetColor } from "../../ThemeColor";
import Desktop from "../../assets/Desktop";
import Mobile from "../../assets/Mobile";
import { useSelector } from "react-redux"; 
/**
 *
 * @param {*} param0
 * @param {string} device This will change how it renders based on whether it is mobile or desktop. Although css will change, this is needed to change the options that are provided to mobile or to desktop.
 * @returns
 */
export const SysMenu = ({ device }) => {
  const height = useSelector((state) => state.ui.h.dash);
  const { doSignOut, uInfo } = useContext(AuthCtx);

  const heightLogo = useSelector((state) => state.ui.h.dashLogo);
  const handleClick = (i) => {
    if (i === 1) {
      doSignOut();
    }
  };

  return (
    <div className="flex z-45">
      <Menu as="div">
        <div
          className={`flex flex-row ${heightLogo} grow-0 shrink-0 justify-center  m-0 p-0    `}
        >
          <Menu.Button
            className={`flex flex-col   justify-center  m-0 p-0   `}
          >
            <SVGLogo height={60}/>
          </Menu.Button>
        </div>

        <Menu.Items
          className={`fixed translate-x-7  rounded-md shadow-xl outline-none     z-45 ring-1   bg-merlin-200   `}
        >
          <div
            className={`flex flex-col justify-around px-1 py-1 space-y-2 divide-y  `}
          >
            <Menu.Item>
              {() => (
                <div>
                  <div
                    className={`w-36 text-base outline-none cursor-default ${GetColor(
                      "caret",
                      200
                    )}`}
                  >
                    {" "}
                    {uInfo.name}
                  </div>
                  <div
                    className={`w-36 text-xs outline-none cursor-default ${GetColor(
                      "caret",
                      200
                    )}`}
                  >
                    {" "}
                    {uInfo.email}{" "}
                  </div>
                </div>
              )}
            </Menu.Item>

            <Menu.Item>
              {({ active }) => (
                <div className="flex flex-row justify-start text-xs w-36">
                  {" "}
                  <button
                    className={`w-36  hover:bg-pelorous-400

                               flex   h-7 rounded-md`}
                    onClick={() => {
                      handleClick(1);
                    }}
                  >
                    <div
                      className={`w-6 text-xs outline-none cursor-default ${GetColor(
                        "caret",
                        200
                      )}`}
                    >
                      {device === "desktop" ? (
                        <Desktop className="w-5 h-5 text-cod-gray-800" />
                      ) : (
                        <Mobile className="w-5 h-5 text-cod-gray-800" />
                      )}
                    </div>
                    <div className="flex flex-col justify-center mt-1.5 ml-2">
                      Logout
                    </div>
                  </button>
                </div>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Menu>
    </div>
  );
};
