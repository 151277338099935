const EChange = {
    none: 0,
    name: 1,
    active: 2,
    occupant: 4,
    rent: 8,
    startMonth: 16,
    startBal: 32,
    isApt: 64,
}; 
export default EChange