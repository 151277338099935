import React, { useEffect, useRef, useState } from "react";
import { useDrag, useDrop } from 'react-dnd'

import { LightBulbIcon, LockClosedIcon } from "@heroicons/react/20/solid";

import { RndKey } from "../../functions/RndKey";
import HideBtn from "./HdrMenu/MenuBtn";
import { useDispatch, useSelector } from "react-redux";
import UnitTblActions from "../../store/slices/uoSlice/uoFxs";
import DeepCopyTree from "../../store/cloudFx/DeepCopyTree";
import { WriteUpdateColData } from "../../store/slices/uoSlice/uoRdxs/uoFetchRdx";
import { createAction } from "@reduxjs/toolkit";



const Header = ({ name }) => {

  const [lastDragOver, setLastDragOver] = useState("");
  const dispatch = useDispatch();
  const pref = useSelector((state) => state.uo.col.pref);
  const dragIndex = useSelector((state) => state.uo.drag.col);
  const dropIndex = useSelector((state) => state.uo.drag.buoy);
  const dragStart = useSelector((state) => state.uo.drag.started);
  const dragEnd = useSelector((state) => state.uo.drag.ended);

  const dragName = useSelector((state) => state.uo.drag.dragName);
  const dropName = useSelector((state) => state.uo.drag.buoyName);


  function updateColumnPrefs(pf) {
    return createAction('uo/fetch/fulfilled', function prepare(pf) { return { payload: pf } });
  }
  function notifyDragStart(colIndex, colName) {
    var act = createAction('uo/setDragCol', function prepare(colIndex, colName) { return { payload: { col: colIndex, name: colName } } });
    dispatch(act(colIndex, colName));
  }
  function notifyDragOver(colIndex, colName) {
    var act = createAction('uo/setDragBuoy', function prepare(colIndex, colName) { return { payload: { col: colIndex, name: colName } } });
    dispatch(act(colIndex, colName));
  }

  function notifyDragCancel() {
    var act = createAction('uo/resetDrag');
    dispatch(act());
  }
  const doUpdatePref = (pf) => {

    const extraPref = WriteUpdateColData(pf);
    dispatch(updateColumnPrefs()(extraPref))
  }

  const handleMoveColumns = () => {
    const copyPref = DeepCopyTree(pref);
    var moveIndex = dragIndex;
    var buoyIndex = dropIndex;
    var keys = Object.keys(copyPref);
   // console.log(`Moving index  ${moveIndex} to  ${buoyIndex}`)

    if (moveIndex < buoyIndex) {
   //     console.log(`Moving ${dragName} @index:${moveIndex} to after ${dropName} @index:${buoyIndex}`);
      keys.forEach((k) => {
        if ((copyPref[k].index > moveIndex) && (copyPref[k].index < buoyIndex + 1)) { copyPref[k].index = copyPref[k].index - 1; }

      });
      copyPref[dragName].index = buoyIndex;
      doUpdatePref(copyPref)
      //   console.log(copyPref);
    } else  if(moveIndex>buoyIndex){
   //    console.log(`Moving ${dragName} @index:${moveIndex} to before ${dropName} @index:${buoyIndex}`);
      keys.forEach((k) => {
        if ((copyPref[k].index < moveIndex) && (copyPref[k].index > buoyIndex - 1)) { copyPref[k].index = copyPref[k].index + 1; }

      });

        copyPref[dragName].index=buoyIndex;
        doUpdatePref(copyPref)
    }

   //

 //   console.log(copyPref);
    notifyDragCancel();

  };





  const colWidth = useSelector((state) => state.uo.col.widths[name]);
  const colWidthInputs = useSelector((state) => state.uo.col.widthsInputs[name]);
  const indexCol = useSelector((state) => state.uo.col.odds[name]);
  const oddCol = indexCol % 2 === 1;
  const showCol = useSelector((state) => state.uo.col.pref[name].show);
  const dragCol = useSelector((state) => state.uo.dragCol);
  const hasFocus = useSelector((state) => state.uo.focus.index) > -1;
  const hasEdit = useSelector((state) => state.uo.edit.index) > -1;
  const theItems = useSelector((state) => state.units.items);
  const doLock = hasFocus || hasEdit;
  const theKeys = () => Object.keys(theItems);

  const getColColor = () => {
    if (name === dragName) {
      return oddCol ? ' bg-pelorous-400 ' : ' bg-pelorous-300 ';
    } else if(name===dropName){

      return oddCol ? ' bg-sunshade-400 ' : ' bg-sunshade-300 ';
    }
    else { return oddCol ? 'bg-merlin-300/50' : 'bg-merlin-300'; }
  };


  const activeMode = useSelector((state) => state.uo.active);
  const theUValues = () => theKeys().map((u) => (theItems[u].shadow));
  const getOddRowsData = () => {
    var nextActMode = (activeMode + 1) % 4;
    var uu = [];
    theUValues().forEach((u) => ((nextActMode === 3 || u.active === nextActMode) && uu.push(u)));
    return uu;
  }

  const LockIcon = () => {
    return (doLock) ? <LockClosedIcon className="w-5 h-5 text-cod-gray-950 lg:w-6 lg:h-6" /> : <></>;
  }
  const IconActive = () => {
    if (activeMode === 0) {
      return <><LockIcon /> <LightBulbIcon className="w-5 h-5 text-cod-gray-950 lg:w-6 lg:h-6" /></>;
    } else if (activeMode === 1) {
      return <><LockIcon /><LightBulbIcon className="w-5 h-5 text-sunshade-500 lg:w-6 lg:h-6" /> </>
    } else if (activeMode === 2) {
      return <><LockIcon /><LightBulbIcon className="w-5 h-5 text-pelorous-400 lg:w-6 lg:h-6" /> </>
    } else {
      return <><LockIcon /><LightBulbIcon className="w-5 h-5 text-malachite-900 lg:w-6 lg:h-6" /> </>
    }
  };
  const getContent = () => {
    if (name === 'active') {
      return (<div className='flex justify-center'><div key={RndKey()} className='flex justify-center h-3 w-9 shrink-0' onClick={() => {


        if (!doLock) { dispatch(UnitTblActions.activeAction()(((activeMode + 1) % 4), getOddRowsData())); }

      }} >
        {IconActive()}
      </div></div>);
    }
    else {
      return (<div className='flex justify-center'><HideBtn name={name} /></div>)
    }
  }
  const doMoveColumn = (e) => {

    if (dragIndex > -1) {
      var worked = e.dataTransfer?.dropEffect === "move";
      if (worked) {
      //  console.log(`Drop index is ${dropIndex} and drag index is ${dragIndex}`)
        handleMoveColumns();
      } else if (dropIndex === -1 || (dropIndex === dragIndex)) {
        notifyDragCancel();
      }
      else {
     //   console.log(`else Drop index is ${dropIndex} and drag index is ${dragIndex}`)
        handleMoveColumns();
      }
    }
  }



  return (

    <div   key={name}   onTouchStart={() => { }}   onClick={() => { }}
      className={`transition-all h-9 justify-center duration-1000 flex flex-row font-bold  ${getColColor()} ${colWidth}   ${showCol ? '' : 'hidden'} m-0 p-0 shrink-0 grow-0  `}
    >
      <div className={`w-0.5 grow-0 flex-none shrink-0 border-b-merlin-500  border-b-2 hover:cursor-pointer ${getColColor()} `}   ></div>

      <div className={`flex-none ${colWidthInputs}  border-b-merlin-500  border-b-2  mt-1 `}> <div className={`flex flex-row   h-8 justify-center`}> <div className={`flex `}   >{getContent()}</div></div>
      </div>
      <div className={`w-0.5  grow-0 flex-none shrink-0  border-b-merlin-500  border-b-2 hover:cursor-pointer  ${getColColor()} `}   ></div>

    </div>
  );
};

export default Header;



