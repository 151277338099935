import { RndKey } from "../../../functions/RndKey";
import EChange from "../../statics/EChange";



export const UnitsRdx = {
    setCalledForFetch(state,action){
        state.wasCalled=true;
    },
    setDataPart(state,action){
        var index = action.payload.index;
        if (index > -1 && (action.payload.id === state.items[index].id)) {
            var shadow = { ...state.items[index].shadow,...action.payload.data };
            state.items[index].shadow = { ...shadow };
            state.items[index].eChanged = action.payload.eChanged;
        }
    },
    writeData(state,action){
        var index = action.payload.index;
        if (index > -1 && (action.payload.id === state.items[index].id)) {
            var shadow = { ...state.items[index].shadow,...action.payload.data };
            state.items[index].shadow = { ...shadow };
            state.items[index].data={...shadow};
            state.items[index].eChanged = EChange.none;
        }
    },
    mergeShadowData(state, action) {
        var index = action.payload.index;
        if (index > -1 && (action.payload.id === state.items[index].id)) {
            var shadow = { ...state.items[index].shadow };
            var data = { ...state.items[index].data };
            var merged = { ...data, ...shadow };
            state.items[index].data = { ...merged };
            state.items[index].shadow = { ...merged };
            state.items[index].eChanged = EChange.none;
        }
    },
    resetShadowData(state, action) {

        var index = action.payload.index;
        if (index > -1 && (action.payload.id === state.items[index].id)) {
            var shadow = {};
            var keys = Object.keys(state.items[index].shadow);

            Object.keys(state.items[index].shadow).forEach((k)=>{shadow[k]=state.items[index].data[k]});
            state.items[index].shadow = shadow;
            state.items[index].resetToken=RndKey();
            state.items[index].eChanged = EChange.none;
        }
    }
}