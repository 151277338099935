import React, { createContext, useEffect, useRef, useState } from "react";
import { DndContext } from "@dnd-kit/core";
import { DroppableBooths } from "./Droppable";
import { DraggableTicket } from "./Draggable";
import { DroppableMonths } from "./DroppableBig";
import { useDispatch, useSelector } from "react-redux";
import DeepCopyTree from "../../../store/cloudFx/DeepCopyTree";
import { RndIDKey } from "../../../functions/RndIDKey";

import { Brain } from "./Brain";
import txTicketActs from "../../../store/slices/txTicket/acts";
import { DollarText } from "../../../functions/DollarText";
import ResetBtn from "./ResetBtn";
import DragWrap from "./DragWrap";
export const AppCtx = createContext();

function processAmountSumStartEnd(amts) {
  var runBal = 0;
  var copyAmt = DeepCopyTree(amts);
  var newBook = {};
  Object.keys(copyAmt).forEach((a) => {
    let subBal = copyAmt[a][0] - copyAmt[a][1];
    let startRun = runBal;
    runBal += subBal;
    let endBal = runBal;
    newBook[a] = [copyAmt[a][0], copyAmt[a][1], subBal, startRun, endBal];
  });

  return newBook;
}
function App() {
  const [hadInit, setInit] = useState(false);
  const focusID = useSelector((state) => state.uo.focus.id);
  const txRibID = useSelector((state) => state.txRib.unitID);
  const monthIDs = useSelector((state) => state.txRib.months.ids);
  const monthNums = useSelector((state) => state.txRib.months.num);
  const txTickets = useSelector((state) => state.txRib.txs);
  const theTicketData = useSelector((state) => state.txTicket);
  const [locus, setLocus] = useState({});
  const oldFocus = useRef("");
  const dispatch = useDispatch();

  function createTicket(m) {
    let dict = null; //{m:monthsXRef[m],id:RndIDKey(20),pay:{},chg:{},bounced:false,rNum:"",priority:0,chgZ:0,payZ:0,chgSum:0,paySum:0,both:0};

    return dict;
  }
 

  function getDraggableMarkup(dict) {

    return (
      <DragWrap>
      <DraggableTicket
        id={dict.id + "-" + dict.type}
        txID={dict.id}
        type={dict.type}
        rNum={dict.rNum}
        amt={dict.amt}
        key={dict.id + "-" + dict.type}
        bounce={dict.bounce}
      />
      <ResetBtn  id={dict.id + "-" + dict.type}
        txID={dict.id}
        type={dict.type}
        rNum={dict.rNum}
        amt={dict.amt}
        key={dict.id+"-" + dict.type+"rBtn"}
        bounce={dict.bounce}/>
      </DragWrap>
    );
  }

  function doInit() {
    if (focusID !== oldFocus.current) {
      setInit(() => false); 
    }

    if (focusID === txRibID && !hadInit.current) {
      oldFocus.current = focusID;

      let pill = {
        monthIDs: [...monthIDs],
        monthNums: [...monthNums],
        ticketTxs: DeepCopyTree(txTickets),
      };
      dispatch(txTicketActs.setInitData()(pill));

      let txsID = theTicketData.txIDs;
      let txsData = theTicketData.txData;

      let tempLocus = {};

      txsID.forEach((t) => {
        let isPay = txsData[t].both === 1;
        let isChg = txsData[t].both === 2;
        let isBounce = txsData[t].both === 3;
        let temp = {pay:undefined,chg:undefined,bounce:undefined};
        if (isBounce) {
            if(txsData[t].chg?.f>0){temp.chg=getDraggableMarkup({id:t ,type:"chg",rNum:"Bounce Fee",amt:txsData[t].chg.f})}
            temp.pay=getDraggableMarkup({id:t ,type:"pay",rNum:"#" + txsData[t].rNum.substring(7) + "("+DollarText({value:txsData[t].chg.b,digits:5})+")",amt:'b',bounce:true})
            tempLocus[t ]=temp;
        }
        else  if (isChg) {
          temp.chg=getDraggableMarkup({id:t,type:"chg",rNum:txsData[t].rNum,amt:txsData[t].chgSum,bounce:false})
          tempLocus[t]=temp;
        } else  if (isPay) {
          temp.pay=getDraggableMarkup({id:t,type:"pay",rNum:txsData[t].rNum,amt:txsData[t].paySum,bounce:false})
          tempLocus[t]=temp;
        }
      });

      setLocus(() => tempLocus);
      setInit(() => true); 
    }
  }

  useEffect(() => {
    if (!hadInit.current) {
      doInit();
    }
  }, [focusID, oldFocus.current, hadInit]);

  if (!hadInit) {
    return <div>Needs init {hadInit ? "true" : "false"}</div>;
  }
  if (!focusID) {
    return <div>Needs focus</div>;
  }

  if (focusID !== oldFocus.current) {
    return <div>Needs match !</div>;
  } else {
    return (
      <AppCtx.Provider value={{}}>
        <Brain focusID={focusID} locus={locus} />
      </AppCtx.Provider>
    );
  }
}

export default App;
