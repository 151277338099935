
import { Menu } from "@headlessui/react";
import { ArrowLeftIcon, CurrencyDollarIcon, LightBulbIcon, LockClosedIcon } from "@heroicons/react/20/solid";
import { RndKey } from "../../../functions/RndKey";
import { useDispatch, useSelector } from "react-redux";
import LeftArrowIcon from "../../../assets/LeftArrowIcon";
import RightArrowIcon from "../../../assets/RightArrowIcon";
import { createAction } from "@reduxjs/toolkit";
import { WriteUpdateColData } from "../../../store/slices/uoSlice/uoRdxs/uoFetchRdx";
import DeepCopyTree from "../../../store/cloudFx/DeepCopyTree";



const MenuItemPart = ({ colName, keyItem, i, handleClickA, handleClickB, rawName, handleClickRent=(()=>{}), addDivider = 0, isMain = false, icon = undefined, isArrows = false,isRent=false }) => {

    const pref = useSelector((state) => state.uo.col.pref);



    function getMenuItemContents() {
        return <Menu.Item key={RndKey()}>
            {({ active }) => (
                <div className="flex flex-row text-base w-32 justify-around "    >
                    {!isArrows ? <>  <button
                        className={`w-32 z-31  ${active && 'bg-pelorous-500'}

                               flex   h-7 rounded-md`}
                        onClick={() => { handleClickA(rawName) }}
                    >
                        <div className='flex w-5 z-31  mt-1 p-0'   >{icon !== undefined ? (icon) : (keyItem === 'name' ? <LockClosedIcon className='w-4 h-4' /> : <LightBulbIcon className={`w-4 h-4 ${pref[rawName].show ? 'text-meteor-500' : 'text-cod-gray-900'}`} />)}</div>
                        <div className='flex m-0 z-31  p-0'  >{keyItem}</div>

                    </button> </> :
                        <div className='flex flex-row justify-between  h-4 grow-0 shrink-0 w-32'>
                            <div className='flex flex-col justify-center h-3 mt-1 '>
                                <button className="hover:text-pelorous-500 hover:scale-105 scale-95 text-bilbao-600 " onClick={() => { handleClickA('left') }}>
                                    <LeftArrowIcon className="flex" />
                                </button></div>
                                {isRent&&<div className='h-4 w-4' onClick={() => { handleClickRent( ) }}><CurrencyDollarIcon /></div>}
                            <div className='flex flex-col justify-center h-3  mt-1'>
                                <button className="hover:text-pelorous-500 hover:scale-105 scale-95 text-bilbao-600" onClick={() => { handleClickB() }}>
                                    <RightArrowIcon className="flex" />
                                </button>
                            </div></div>
                    }
                </div>
            )}
        </Menu.Item>
    }

    if (isMain || (colName !== 'name' && colName !== keyItem)) {
        if (addDivider === 1) {
            return <div className='pt-1 border-t-2 border-t-merlin-300 '>{getMenuItemContents()}</div>
        }
        else if (addDivider === 2) {
            return <div className='pb-1 border-b-2 border-b-merlin-300'>{getMenuItemContents()}</div>
        }
        else if (addDivider === 3) {
            return <div className='py-1 border-t-2 border-t-merlin-300 border-b-2 border-b-merlin-300'>{getMenuItemContents()}</div>
        }
        else {
            return <div className='py-0.5'>{getMenuItemContents()}</div>;
        }
    }
    else {
        return <></>
    }

}

export default MenuItemPart