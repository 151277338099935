import { ArrowPathIcon  } from '@heroicons/react/20/solid';
import React, { useContext, useEffect } from 'react'
import UnitTblActions from '../../../../store/slices/uoSlice/uoFxs';
import { useDispatch, useSelector } from 'react-redux';



const ResetBtn = ({ index }) => {

  const dispatch = useDispatch();

  const id = useSelector((state) => state.units.items[index].id);
  const iconSize = useSelector((state)=>state.ui.iconSize);
  const eChanged = useSelector((state) => state.units.items[index].eChanged);

  const height = useSelector((state) => state.ui.h.line);
  const handleClick= () => {
    dispatch(UnitTblActions.resetAction()(id,index));
  }
  useEffect(()=>{


  },[])

  return (
      <div className={`flex flex-col justify-center ${height}  w-fit    `}  onClick={() => { handleClick() }}>

        <div className={`  ${iconSize}`}><div className={`${eChanged!==0?'visible text-bilbao-700':'invisible'}`}>

          <ArrowPathIcon  />

          </div>
          </div>

        </div>

  )
}

export default ResetBtn