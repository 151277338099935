import React from "react";
import Cell from "../cells/Cell";
import { useSelector } from "react-redux";

import Name from "../cells/SubCells/Name/Name";
import Occupant from "../cells/SubCells/Occupant/Occupant";
import Rent from "../cells/SubCells/Rent/Rent";
import QuadBtn from "../cells/SubCells/QuadBtns/QBtn";
import StartBal from "../cells/SubCells/StartBal/StartBal";
import EndRBal from "../cells/SubCells/EndRBal/EndRBal";
import EndRMonth from "../cells/SubCells/EndRBal/EndRMonth";
import StartMonth from "../cells/SubCells/StartBal/StartMonth";


const picker =(key,index)=> {
  const dict ={}
  dict['active']=<QuadBtn index={index} />;
  dict['name']=<Name index={index} />;
  dict['endRBal']= <EndRBal index={index} />;
  dict['endRMonth']= <EndRMonth index={index} />;
  dict['occupant']= <Occupant index={index} />;
  dict['startBal']= <StartBal index={index} />;
  dict['startMonth']= <StartMonth index={index} />;
  dict['rent']=<Rent index={index} />;
  return dict[key];
};
const TableRow = ({ index, height }) => {
  const bg = useSelector((state) => state.ui.bg.unitTable.back);
  const colOrder = useSelector((state) => state.uo.col.odds);
  return (
    <div className={`flex  flex-row   ${bg} ${height}  `}>
      {Object.keys(colOrder).map((k, i) => {
        return (
          colOrder[k] > -1 &&(
            <Cell
              colName={k}
              index={index}
              colIndex={colOrder[k]}
              key={k.id + "k" + i}
             >
              {picker(k,index)}
              </Cell>
          )
        );
      })}
    </div>
  );
};

export default TableRow;
