
export const USDollar = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});
export function AsMoney(n,withD=false,z=10) {
  if (isNaN(n)||(n===0)) { return " $   —       "; }
  var str = USDollar.format(n);
  str = str.replace('$', '');
  if (isNaN(n)||(n===0)) {
    str = "";
  }
  else if (n < 0) {
    str = str.replace('-', '');
  }
  str = str.padStart(z, ' ');
  if(!withD){str = str.replace(/\.00/,'');}else {str = str.replace(/\.00/,'.  '); }
  if (n < 0) { str = "($" + str + ")"; } else { str = " $" + str + " "; }
  return str;
}


export function AsMoneyShort(n,withD=false,z=10,blankIfZero=false) {
  var q = (n!==undefined||isNaN(n))?Math.floor(Math.round(n*100)):0;
  q/=100;
  if (q===0) {
    if(blankIfZero){return ""}
    var strA = "   —  ".padStart(z-2, ' ');
    return " $"+strA+(withD?"   ":"");
  }
  var str = USDollar.format(q);
  str = str.replace('$', '');
  if (isNaN(q)||(q===0)) {
    var strC = "   —  ".padStart(z-2, ' ');
    return (blankIfZero?"":" $"+strC+(withD?"   ":""));
  }
  else if (q < 0) {
    str = str.replace('-', '');
  }
  str = str.padStart(z, ' ');
  if(!withD){str = str.replace(/\.00/,'');}else {str = str.replace(/\.00/,'.  '); }
  if (q < 0) { str = "($" + str + ")"; } else { str = " $" + str + " "; }
  return str;
}

