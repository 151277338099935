import {   createAsyncThunk } from "@reduxjs/toolkit";
import { collection, getDocs, orderBy, query } from "firebase/firestore";
import { db } from "../../../auth/fbase"; 
 

function GetQuadCount(count,v,i){
  var answer = [-1,-1,-1,i];
  answer[v]=count[v];
  count[v]++;
  return answer;
}


export const fetchUnits = createAsyncThunk("units/fetch", async () => {
  const uData = await GetUnitData();
  

  var keys = Object.keys(uData);
  var index=[0,0,0,0];
  keys.forEach((k,i)=>{
    uData[k].shadow['odd']=GetQuadCount(index,uData[k].shadow.active,i);
  })
  return uData;
});

export async function GetUnitData() {
  var units = [];
  const q = query(collection(db, "units"), orderBy("order"));

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    units.push(GetUnitTxsComboUData(doc,true));
  });
  return Promise.all(units);
}

export async function GetRawUnitTx(id) {

  const q = query(collection(db, "units", id, "account"));
  const querySnapshot = await getDocs(q);
  var txs = {};
  querySnapshot.forEach((doc) => {
    if (doc.exists()) {
      const dD = doc.data();
      const iD = { id: doc.id };
      const nD = { ...dD, ...iD };
      txs[doc.id]={ ...nD };
    }
  });

  return {id:id,txs:txs};
}



async function GetUnitTx(id) {

  const q = query(collection(db, "units", id, "account"), orderBy("m"));
  const querySnapshot = await getDocs(q);
  var txs = [];
  querySnapshot.forEach((doc) => {
    if (doc.exists()) {
      const dD = doc.data();
      const iD = { id: doc.id };
      const nD = { ...dD, ...iD };
      txs.push({ dataTx: { ...nD }, eChangedTx: 0 });
    }
  });

  return txs;
}
async function GetUnitTxsComboUData(doc, getTxs = false) {
  const nD = doc.data();
  const shadow = cleanShadow(nD);
  if (getTxs) {
    const data = await GetUnitTx(doc.id);
    return {
      id: doc.id,
      data: nD,
      shadow: { ...shadow },
      eChanged: 0,
      resetToken:0,
      txs: data,
      txLen: data.length,
    };
  } else {
    return {
      id: doc.id,
      data: nD,
      shadow: { ...shadow },
      eChanged: 0,
      resetToken:0,
      txs: [],
      txLen: 0,
    };
  }
}

function addIfHasValue(rawData, key, dict, keepZero = []) {
  if (!keepKeys.includes(key)) {
    return;
  }
  var sample = rawData;
  if (sample === undefined) {
    return;
  }
  if (sample === 0) {
    if (keepZero.includes(key)) {
      dict[key] = 0;
    } else {
      return;
    }
    if (sample.length === 0) {
      if (keepZero.includes(key)) {
        dict[key] = "";
      }
    } else {
      return;
    }
  }
  dict[key] = sample;
}

const keepKeys = [
  "id",
  "name",
  "rent",
  "startBal",
  "startMonth",
  "endRMonth",
  "endRBal",
  "occupant",
  "active",
  "isApt",
];

function cleanShadow(data) {
  var dict = {};
  var keys = Object.keys(data);
  keys.forEach((k) => {
    addIfHasValue(data[k], k, dict);
  });
  return dict;
}
