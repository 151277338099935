import React from "react";
 
import OccupantBox from "./OccupantBox";
import { useSelector } from "react-redux";

const Occupant = ({ index }) => {


  const occupant = useSelector((state) => state.units.items[index].shadow.occupant);
  const id = useSelector((state) => state.units.items[index].id);
  const colWidth = useSelector((state) => state.uo.col.widthsInputs.occupant);
  const edit  = useSelector((state) => state.uo.edit.id);
 
   

  const height = useSelector((state) =>  state.ui.h.line );

  

  return (
    <div
      className={`flex flex-col justify-start   ${height}   ${colWidth} `}
    ><div className="flex flex-row">
      <div
        className={`  h-fit  ${
          edit=== id ? "collapse w-0 " : "visible"
        } `}
      >
        {occupant}
      </div>
      <div
        className={`flex  ${
          edit === id ? "visible" : "collapse w-0"
        } text-crimson-900`}
      >
        <OccupantBox index={index} />
      </div></div>
    </div>
  );
};

export default Occupant;
