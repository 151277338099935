import { createContext, useEffect, useState } from 'react';
import { auth } from './fbase';
import { useNavigate } from 'react-router-dom';

import {
    GoogleAuthProvider,
    signInWithRedirect,
    onAuthStateChanged,
    signOut,
    getRedirectResult
} from "firebase/auth"; 

export const AuthCtx = createContext();

class UserInfo{
    constructor(email, name, photo)
    {
        this.email = email;
        this.name=name;
        this.photo=photo;
    }
    data(){
        return {email:this.email, name:this.name, photo:this.photo}
    }
}

export function AuthProvider({ children }) {


    const [uInfo,setUInfo]=useState();
    const [provider,setProvider] = useState();
    const [signedIn,setSignedIn] = useState(false); 
    const hasProvider = ()=>provider!==undefined;

    const initProvider=()=>{
        var prov = new GoogleAuthProvider();
        prov.addScope("profile");
        prov.addScope("email");
        prov.setCustomParameters({ prompt: 'select_account' });
        setProvider(prov);
    }
    const googleSignIn = async() => {
        var result = await signInWithRedirect(auth, provider);
        // var userCred = await getRedirectResult(auth);
        // if(userCred&&!signedIn){
        //     setUInfo(new UserInfo(result.user.email,result.user.displayName,result.user.photoURL));
        //     setSignedIn(true);
        // }
    }
    const doSignIn=()=>{
        googleSignIn().then(()=>{
            setSignedIn(true);;
        });
    }
    const doSignOut = () => {
        console.log('doing sign out');
        signOut(auth).then((result) => {
            setUInfo();

            localStorage.clear(); //for localStorage
            sessionStorage.clear(); //for sessionStorage
            setSignedIn(false);
        })
    }

    const stateChangeCallBack =(currentUser) => {

        if (currentUser) {
            setUInfo(new UserInfo(currentUser.email,currentUser.displayName,currentUser.photoURL));
            setSignedIn(true);
        }
    }

    useEffect(() => {
        if(!hasProvider()){initProvider();}

        const unsubscribe = onAuthStateChanged(auth,stateChangeCallBack);
        return () => {unsubscribe();}
        }, [auth]);

    return (
        <AuthCtx.Provider value={{uInfo,doSignOut,doSignIn,signedIn}}>
            {children}
        </AuthCtx.Provider>
    );
};

