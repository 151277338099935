import React from 'react'
import { RndKey } from '../functions/RndKey';

const SVGAlpha = ({word,className}) => {
    const letters = word.split('');
    const xw = 5;
    const z = letters.length;
    const w = (xw+2)*z;
    const h = 8;

    return  <div className='flex'>
    <svg xmlns="http://www.w3.org/2000/svg" width={w} height={h} viewBox={'0 0 '+(w)+" "+h} strokeWidth="0.125" stroke="#000000"  strokeLinecap="round" strokeLinejoin="round">
       {letters.map((ltr,i)=> WrapLetter(ltr,i,6.5))}
  </svg></div>

}

export const CHE=({fill,height=50})=>{
    
    const h = height;
    const xw = (9*h/16);
    const z = 3;
    const w = .95* (xw)*z;
    const x = .95*h/2;
    const scale=h/8;
    return  <div className='flex'><div className='flex flex-row'>
    <svg xmlns="http://www.w3.org/2000/svg" width={w}  transform={`translate(${-x*0.04})`} height={h} viewBox={'0 0 '+(w)+" "+h}   strokeLinecap="round" strokeLinejoin="round">
   <g  transform={`scale(${scale} ${scale})`} > <GetLetter ltr={'C'}fill={fill[0]}/></g>  
   <g  transform={`translate(${x}) scale(${scale} ${scale})`}> <GetLetter ltr={'H'} fill={fill[1]}/> </g>  
   <g  transform={`translate(${x*2}) scale(${scale} ${scale})`}>   <GetLetter ltr={'E'}  fill={fill[2]}/></g>
  </svg></div></div>
}
 
function WrapLetter({ltr,i,x,fill,w,h} ){
    return <g   key={RndKey()}  width={w} height={h} transform={`translate(${Number(i)*Number(x)})`} >{GetLetter(ltr,fill)}</g>
}
function GetLetter({ltr,fill}){
    if(ltr==='A'){return <path fill={fill} letter="A" d=" m 6.48 7.76 h -1.14 l -.53 -1.67 H 1.62 l -.54 1.67 H 0 L 2.54 .11 h 1.43 l 2.51 7.66 Z m -1.97 -2.6 L 3.22 1.07 l -1.29 4.1 h 2.59 Z "/> }
    if(ltr==='B'){return <path fill={fill} letter="B" d=" m 6.0 5.47 c 0 .37 -.07 .69 -.21 .98 s -.35 .52 -.61 .72 -.58 .34 -.96 .45 -.79 .15 -1.25 .15 h -2 V .11 h 2.19 c 1.71 0 2.56 .62 2.56 1.86 0 .41 -.1 .77 -.3 1.07 s -.52 .52 -.96 .66 c .21 .04 .4 .11 .59 .2 s .35 .21 .49 .36 .25 .32 .33 .53 .12 .43 .12 .69 Z m -1.36 -3.36 c 0 -.16 -.02 -.3 -.07 -.44 s -.13 -.26 -.25 -.36 -.28 -.18 -.47 -.24 -.45 -.09 -.76 -.09 h -1.08 v 2.4 h 1.04 c .24 0 .45 -.03 .65 -.08 s .36 -.13 .5 -.23 .25 -.24 .32 -.4 .11 -.35 .11 -.57 Z m .25 3.4 c 0 -.2 -.04 -.37 -.12 -.53 s -.2 -.29 -.36 -.4 -.35 -.19 -.57 -.25 -.48 -.09 -.76 -.09 h -1.07 v 2.64 h 1.1 c .6 0 1.04 -.11 1.34 -.33 s .44 -.57 .44 -1.04 Z "/> }
    if(ltr==='C'){return <path fill={fill} letter="C" d=" m 5.47 7.48 c -.61 .25 -1.26 .38 -1.93 .38 -1.08 0 -1.91 -.32 -2.49 -.97 s -.87 -1.6 -.87 -2.87 c 0 -.61 .08 -1.17 .24 -1.66 s .39 -.92 .69 -1.26 .66 -.61 1.08 -.8 S 3.07 .01 3.6 .01 c .36 0 .69 .03 .99 .09 s .6 .15 .88 .28 v 1.03 c -.28 -.15 -.56 -.27 -.86 -.35 s -.62 -.12 -.97 -.12 -.68 .07 -.98 .2 -.54 .33 -.74 .59 -.36 .57 -.47 .94 -.16 .79 -.16 1.26 c 0 .99 .2 1.74 .6 2.24 s .99 .76 1.77 .76 c .33 0 .64 -.04 .94 -.11 s .59 -.18 .87 -.32 v .98 Z "/> }
    if(ltr==='D'){return <path fill={fill} letter="D" d=" m 6.2 3.85 c 0 .37 -.03 .72 -.08 1.05 s -.15 .65 -.27 .94 -.29 .55 -.49 .79 -.44 .44 -.73 .61 -.62 .3 -1.01 .39 -.82 .14 -1.31 .14 h -1.64 V .11 h 1.97 c 1.2 0 2.09 .31 2.67 .92 s .88 1.56 .88 2.82 Z m -1.1 .08 c 0 -.54 -.05 -1 -.15 -1.38 s -.26 -.68 -.46 -.91 -.47 -.4 -.78 -.5 -.68 -.16 -1.1 -.16 h -.9 v 5.88 h .78 c 1.74 0 2.61 -.98 2.61 -2.94 Z "/> }
    if(ltr==='E'){return <path fill={fill} letter="E" d=" m 5.5 7.76 h -4.35 V .11 h 4.35 v .88 h -3.31 v 2.37 h 3.18 v .88 h -3.18 v 2.64 h 3.31 v .89 Z "/> }
    if(ltr==='F'){return <path fill={fill} letter="F" d=" m 5.5 1 h -3.25 v 2.48 h 3.08 v .87 h -3.08 v 3.41 h -1.07 V .11 h 4.32 v .89 Z "/> }
    if(ltr==='G'){return <path fill={fill} letter="G" d=" m 5.75 1.42 c -.29 -.15 -.58 -.27 -.88 -.35 s -.63 -.13 -.98 -.13 c -.41 0 -.77 .07 -1.08 .22 s -.58 .35 -.8 .61 -.38 .58 -.5 .95 -.17 .78 -.17 1.23 .05 .89 .14 1.26 .24 .69 .44 .94 .45 .46 .76 .59 .68 .21 1.12 .21 c .07 0 .15 0 .24 -.01 s .17 -.02 .26 -.03 .17 -.03 .25 -.05 .15 -.04 .21 -.06 v -2.44 h -1.56 v -.86 h 2.6 v 3.91 c -.16 .07 -.33 .14 -.51 .2 s -.36 .11 -.54 .15 -.37 .07 -.55 .09 -.36 .03 -.53 .03 c -.52 0 -1 -.08 -1.42 -.25 s -.78 -.41 -1.07 -.73 -.52 -.72 -.68 -1.2 -.24 -1.04 -.24 -1.67 .09 -1.19 .26 -1.69 .42 -.92 .74 -1.27 .7 -.61 1.15 -.79 .94 -.27 1.48 -.27 c .34 0 .67 .03 .98 .09 s .61 .16 .89 .29 v 1.04 Z "/> }
    if(ltr==='H'){return <path fill={fill} letter="H" d=" m 6 7.76 h -1.04 v -3.52 h -3.22 v 3.52 h -1.04 V .11 h 1.04 v 3.24 h 3.22 V .11 h 1.04 v 7.66 Z "/> }
    if(ltr==='I'){return <path fill={fill} letter="I" d=" m 2.5 .98 h -1.76 V .11 h 4.58 v .88 h -1.76 v 5.89 h 1.76 v .89 h -4.58 v -.89 h 1.76 V .98 Z "/> }
    if(ltr==='J'){return <path fill={fill} letter="J" d=" m 5.46 .11 v 5.32 c 0 .33 -.05 .64 -.15 .93 s -.25 .55 -.45 .77 -.45 .39 -.75 .52 -.65 .19 -1.06 .19 c -.15 0 -.31 -.01 -.47 -.03 s -.31 -.05 -.46 -.09 -.28 -.08 -.41 -.13 -.24 -.11 -.33 -.17 v -1.05 c .24 .18 .51 .31 .81 .42 s .59 .15 .88 .15 c .43 0 .75 -.13 .98 -.39 s .35 -.63 .35 -1.12 V 1.01 h -2.91 V .11 h 3.97 Z "/> }
    if(ltr==='K'){return <path fill={fill} letter="K" d=" m 6.05 7.76 h -1.31 l -2.87 -3.76 v 3.76 h -1.04 V .11 h 1.04 v 3.56 l 2.81 -3.56 h 1.24 l -3.02 3.64 3.16 4.02 Z "/> }
    if(ltr==='L'){return <path fill={fill} letter="L" d=" m 5.59 7.76 h -4.41 V .11 h 1.06 v 6.77 h 3.35 v .89 Z "/> }
    if(ltr==='M'){return <path fill={fill} letter="M" d=" m 6.42 7.76 h -1.02 l -.15 -4.78 -.06 -1.83 -.36 1.07 -1.13 3.05 h -.72 l -1.08 -2.93 -.36 -1.18 -.02 1.92 -.13 4.69 h -.98 l .38 -7.66 h 1.24 l 1.03 2.88 .33 .97 .32 -.97 1.08 -2.88 h 1.27 l .37 7.66 Z "/> }
    if(ltr==='N'){return <path fill={fill} letter="N" d=" m 6.02 7.76 h -1.36 l -2.23 -4.78 -.64 -1.54 v 6.31 h -.97 V .11 h 1.34 l 2.13 4.52 .77 1.75 V .11 h .97 v 7.66 Z "/> }
    if(ltr==='O'){return <path fill={fill} letter="O" d=" m 6.2 3.88 c 0 .69 -.08 1.28 -.24 1.78 s -.38 .91 -.66 1.24 -.6 .57 -.97 .73 -.76 .24 -1.17 .24 c -.49 0 -.92 -.09 -1.29 -.27 s -.67 -.43 -.91 -.77 -.42 -.75 -.54 -1.23 -.18 -1.03 -.18 -1.63 c 0 -.68 .08 -1.27 .24 -1.77 s .38 -.91 .65 -1.24 .6 -.57 .96 -.73 .76 -.24 1.17 -.24 c .49 0 .92 .09 1.29 .27 s .67 .44 .91 .77 .42 .74 .54 1.22 .18 1.02 .18 1.62 Z m -1.08 .08 c 0 -.45 -.03 -.86 -.1 -1.23 s -.18 -.69 -.33 -.96 -.35 -.48 -.59 -.63 -.53 -.22 -.87 -.22 -.61 .08 -.85 .24 -.43 .37 -.58 .64 -.27 .59 -.34 .96 -.11 .75 -.11 1.16 c 0 .45 .04 .87 .11 1.24 s .18 .69 .33 .96 .34 .48 .58 .62 .53 .22 .86 .22 .61 -.08 .85 -.24 .43 -.37 .59 -.64 .27 -.59 .34 -.95 .11 -.75 .11 -1.17 Z "/> }
    if(ltr==='P'){return <path fill={fill} letter="P" d=" m 5.86 2.45 c 0 .31 -.06 .62 -.18 .93 s -.3 .58 -.54 .82 -.56 .44 -.94 .59 -.84 .23 -1.37 .23 h -.95 v 2.75 h -1.04 V .11 h 2.16 c .38 0 .74 .04 1.09 .13 s .65 .22 .92 .41 .47 .43 .63 .73 .23 .66 .23 1.08 Z m -1.08 .05 c 0 -.49 -.16 -.87 -.48 -1.13 s -.77 -.39 -1.35 -.39 h -1.07 v 3.15 h .97 c .62 0 1.09 -.13 1.43 -.4 s .5 -.68 .5 -1.22 Z "/> }
    if(ltr==='Q'){return <path fill={fill} letter="Q" d=" m 2.74 7.83 c -.41 -.06 -.76 -.19 -1.06 -.39 s -.55 -.46 -.75 -.79 -.35 -.71 -.45 -1.15 -.15 -.93 -.15 -1.47 c 0 -.7 .08 -1.3 .24 -1.8 s .38 -.93 .65 -1.26 .6 -.57 .96 -.73 .76 -.24 1.17 -.24 c .49 0 .92 .09 1.29 .27 s .67 .43 .91 .77 .42 .74 .54 1.21 .18 1.01 .18 1.6 c 0 .62 -.07 1.17 -.2 1.64 s -.31 .87 -.54 1.2 -.5 .59 -.81 .77 -.65 .31 -1.01 .37 c .06 .34 .2 .62 .41 .83 s .52 .31 .91 .31 c .19 0 .37 -.03 .55 -.09 s .37 -.17 .55 -.32 l .46 .72 c -.26 .21 -.52 .36 -.8 .45 s -.55 .13 -.84 .13 c -.31 0 -.6 -.04 -.86 -.13 s -.49 -.21 -.68 -.38 -.35 -.38 -.47 -.64 -.19 -.55 -.21 -.89 Z m 2.46 -3.86 c 0 -.45 -.03 -.87 -.1 -1.24 s -.18 -.69 -.33 -.96 -.35 -.48 -.59 -.62 -.53 -.22 -.87 -.22 -.61 .08 -.85 .23 -.43 .37 -.58 .64 -.27 .58 -.34 .94 -.11 .74 -.11 1.14 c 0 .46 .04 .89 .11 1.26 s .18 .7 .33 .97 .34 .48 .58 .62 .53 .22 .86 .22 .61 -.08 .85 -.23 .43 -.37 .59 -.64 .27 -.58 .34 -.95 .11 -.75 .11 -1.16 Z "/> }
    if(ltr==='R'){return <path fill={fill} letter="R" d=" m 5.88 7.76 h -1.18 l -1.14 -2.45 c -.09 -.19 -.17 -.34 -.26 -.47 s -.19 -.22 -.29 -.29 -.22 -.12 -.34 -.16 -.26 -.05 -.41 -.05 h -.49 v 3.41 h -1.04 V .11 h 2.05 c .45 0 .83 .05 1.15 .15 s .58 .23 .79 .41 .36 .39 .45 .64 .14 .52 .14 .82 c 0 .24 -.04 .46 -.11 .68 s -.17 .41 -.31 .58 -.31 .32 -.52 .45 -.44 .22 -.71 .28 c .21 .07 .4 .2 .55 .39 s .3 .43 .46 .74 l 1.21 2.52 Z m -1.67 -5.55 c 0 -.41 -.13 -.72 -.38 -.92 s -.62 -.3 -1.08 -.3 h -.98 v 2.53 h .84 c .25 0 .47 -.03 .67 -.08 s .37 -.14 .5 -.25 .25 -.25 .32 -.41 .11 -.35 .11 -.56 Z "/> }
    if(ltr==='S'){return <path fill={fill} letter="S" d=" m 6.08 5.69 c 0 .36 -.07 .68 -.22 .96 s -.36 .5 -.62 .68 -.58 .32 -.96 .41 -.78 .13 -1.23 .13 c -.2 0 -.41 0 -.61 -.02 s -.39 -.04 -.58 -.06 -.36 -.05 -.52 -.08 -.31 -.06 -.45 -.1 v -1.01 c .29 .11 .62 .2 .99 .26 s .78 .09 1.25 .09 c .34 0 .62 -.03 .86 -.08 s .43 -.13 .58 -.23 .26 -.23 .33 -.38 .1 -.32 .1 -.51 c 0 -.21 -.06 -.38 -.17 -.53 s -.27 -.28 -.45 -.39 -.4 -.22 -.64 -.32 -.49 -.19 -.74 -.3 -.5 -.21 -.74 -.33 -.45 -.26 -.64 -.42 -.34 -.35 -.45 -.57 -.17 -.48 -.17 -.78 c 0 -.26 .05 -.52 .16 -.77 s .28 -.48 .51 -.68 .53 -.36 .89 -.48 .79 -.18 1.29 -.18 c .13 0 .27 0 .42 .02 s .3 .03 .46 .05 .31 .05 .46 .07 .29 .06 .42 .09 v .94 c -.3 -.09 -.6 -.15 -.9 -.2 s -.59 -.07 -.87 -.07 c -.6 0 -1.04 .1 -1.32 .3 s -.42 .47 -.42 .8 c 0 .21 .06 .38 .17 .53 s .27 .28 .45 .4 .4 .22 .64 .32 .49 .19 .74 .3 .5 .21 .74 .33 .45 .26 .64 .43 .34 .36 .45 .58 .17 .49 .17 .79 Z "/> }
    if(ltr==='T'){return <path fill={fill} letter="T" d=" m 6.12 1 h -2.27 v 6.77 h -1.05 V 1 h -2.27 V .11 h 5.59 v .89 Z "/> }
    if(ltr==='U'){return <path fill={fill} letter="U" d=" m 5.86 5.1 c 0 .42 -.06 .8 -.18 1.15 s -.29 .63 -.52 .87 -.51 .43 -.85 .56 -.72 .2 -1.15 .2 c -.48 0 -.88 -.06 -1.21 -.19 s -.6 -.3 -.81 -.53 -.36 -.5 -.46 -.82 -.14 -.68 -.14 -1.07 V .11 h 1.04 v 5.07 c 0 .3 .03 .56 .08 .79 s .15 .42 .28 .57 .3 .27 .5 .35 .46 .12 .75 .12 c .55 0 .96 -.16 1.23 -.48 s .4 -.77 .4 -1.35 V .11 h 1.04 v 4.99 Z "/> }
    if(ltr==='V'){return <path fill={fill} letter="V" d=" m 6.48 .11 l -2.6 7.66 h -1.41 l -2.54 -7.66 h 1.18 l 1.65 5.16 .47 1.51 .48 -1.51 1.66 -5.16 h 1.11 Z "/> }
    if(ltr==='W'){return <path fill={fill} letter="W" d=" m 6.48 .11 l -.52 7.66 h -1.35 l -.95 -2.72 -.26 -.87 -.28 .94 -.88 2.65 h -1.31 l -.53 -7.66 h .98 l .3 5.21 .09 1.43 .37 -1.21 .93 -2.84 h .72 l 1.07 3.05 .36 1.01 .02 -1.05 .3 -5.58 h .93 Z "/> }
    if(ltr==='X'){return <path fill={fill} letter="X" d=" m 6.48 7.76 h -1.32 l -1.86 -3.09 -1.87 3.09 h -1.3 l 2.55 -3.91 -2.34 -3.74 h 1.24 l 1.75 2.88 1.76 -2.88 h 1.2 l -2.35 3.7 2.54 3.96 Z "/> }
    if(ltr==='Y'){return <path fill={fill} letter="Y" d=" M 6.48 .11 l -2.77 4.92 v 2.74 h -1.05 v -2.76 l -2.77 -4.9 h 1.26 l 1.52 2.8 .56 1.12 .52 -1.02 1.54 -2.91 h 1.2 Z "/> }
    if(ltr==='Z'){return <path fill={fill} letter="Z" d=" m 6 .93 l -3.91 5.87 h 3.98 v .97 h -5.29 v -.78 l 3.93 -5.93 h -3.83 V .11 h 5.11 v .82 Z "/> }
           return <></>
}
export default SVGAlpha