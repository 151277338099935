const uiInit = {
    textSize:"font-mono text-xs md:text-base lg:text-xl ",
    iconSize:"w-3 h-3 md:w-6 md:h-6 lg:h-6 lg:w-6",
    iconSizeXS:"w-2 h-2 md:w-3 md:h-3 lg:w-6 md:h-6",
    bg:{
        dash:"bg-merlin-200",
        unitTable:{
            back:'bg-merlin-400',
            rowEven:'bg-merlin-200',
            rowOdd:'bg-merlin-300',
            columnEven:'bg-merlin-200/20',
            columnOdd:'bg-merlin-300/20'
        },
        menu:"bg-merlin-200"
    },
    h:{
        line:"h-4 md:h-6 lg:h-10 max-h-6 md:max-h-8 lg:max-h-8 grow-0 m-0 p-0 ",
        dash:"h-12 xs:h-18 lg:h-24",
        dashLogo:"h-20 xs:h-24  lg:h-24 w-20 xs:w-24 lg:w-24", 
    },
    base:"m-0 p-0 shrink-0 grow-0",

  };
  export default uiInit;
  