import { createSlice } from "@reduxjs/toolkit"; 
import  uoInit  from "./uoInit";    
import   UORdx   from "./uoRdx";
import { fetchUOps } from "./uoRdxs/uoFetchRdx"; 

export const uoSlice = createSlice({
  name: "uo",
  initialState:uoInit,
  reducers: {
    ...UORdx
  } ,
  extraReducers: (builder) => {
        builder.addCase(fetchUOps.pending, (state) => {
          state.pending = true;
        });
        builder.addCase(fetchUOps.fulfilled, (state, action) => {
          state.pending = false;
          state.fulfilled=true;
          state.col = { 
            pref: action.payload.colPrefs,
            order: action.payload.colOrder,
            odds: action.payload.colOdds,
            widths:action.payload.colWidths,
            widthsInputs:action.payload.colWidthsInputs,
            titles:action.payload.colTitles,
            justData:action.payload.colJustifyData,
          }; 
        });
        builder.addCase(fetchUOps.rejected, (state, action) => {
          state.isLoading = false;
          state.error = action.error.message;
        });
      },
});

export default uoSlice.reducer;
