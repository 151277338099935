import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { useEffect } from "react";

import UnitTblActions from "../../../../store/slices/uoSlice/uoFxs";

import { useDispatch, useSelector } from "react-redux";
import delayRun from "../../../../functions/Timeout";

const FocusToggle = ({ index }) => {
  const dispatch = useDispatch();

  const id = useSelector((state) => state.units.items[index].id);
  const focusID = useSelector((state) => state.uo.focus.id);
  const focusIndex = useSelector((state) => state.uo.focus.index);
  const editIndex = useSelector((state) => state.uo.edit.index);
  const hasEdit = editIndex > -1;
  const isFocusing = id === focusID;
  const iconSize = useSelector((state) => state.ui.iconSize);
  const hasTxs = useSelector((state) => state.txs.ids?.includes(id));

  const handleClick = () => {
    if (hasEdit) {
      return;
    }
    if (isFocusing) {
      dispatch(UnitTblActions.focusAction()("", -1));
    } else {
      if (focusIndex > -1) {
        dispatch(UnitTblActions.focusAction()("", -1));
        dispatch(UnitTblActions.focusAction()(id, index));
      } else {
        dispatch(UnitTblActions.focusAction()(id, index));
      }
    }
  };

  useEffect(() => {}, [hasTxs]);

  return (
    <div
      className={`flex ${iconSize}  transition-all duration-1000`}
      onClick={() => {
        handleClick();
      }}
    >
      {getIcon(isFocusing, hasTxs)}
    </div>
  );
};

export default FocusToggle;

const getIcon = (inFocus, hasTxs) => {
  if (inFocus) {
    return (
      <MagnifyingGlassIcon
        className={`transition-all duration-500 scale-125 -rotate-45${
          hasTxs ? "text-denim-600" : "text-cod-gray-950"
        }`}
      />
    );
  } else {
    return (
      <MagnifyingGlassIcon
        className={`transition-all duration-500 rotate-90 ${
          !hasTxs ? "text-cod-gray-400" : "text-denim-950"
        } `}
      />
    );
  }
};
