import { createAsyncThunk } from "@reduxjs/toolkit";
import { collection, getDocs, orderBy, query } from "firebase/firestore";

import { db } from "../../../auth/fbase";
import DeepCopyTree from "../../cloudFx/DeepCopyTree";

const fetchTxs = createAsyncThunk("txs/fetch", async () => {
  return { items: {}, ids: [] };
});

export async function GetUnitTx(id) {
  if(id===""||id===undefined){console.log("BLANK ID!"); return;}else{console.log("Have ID "+id)}
  const q = query(collection(db, "units", id, "account"), orderBy("m"));
  const querySnapshot = await getDocs(q);
  var txs = [];
  querySnapshot.forEach((doc) => {
    if (doc.exists()) {
      const dD = doc.data();
      const iD = { id: doc.id };
      const nD = getTheSubTx(dD);
      txs.push({ dataTx: dD, shadowTx: { ...nD, ...iD }, eChangedTx: 0,bounced:nD.bounced});
    }
  });
  sortTheTxs(txs);
  const grouped = groupTheTxs(txs);
  const ordered = orderTheTxs(grouped);

  return DeepCopyTree(ordered);
}

export default fetchTxs;

function assignValue(dict, k, book) {
  if ((dict[k] === true) && (k === "b")) { book[k] = dict.amt; }
  else if ((dict[k]) && (dict[k] !== 0)) {
    book[k] = dict[k];
  }
}

function getValue(i) {
  return i ? i : 0;
}

function compKeyVal(a, b, k) {
  var rDelta = getValue(a[k]) - getValue(b[k]);
  if (rDelta > 0) {
    return -1;
  } else if (rDelta < 0) {
    return 1;
  } else return 0;
}

function addBal(a, b) {
  var s = a + b;
  s *= 100;
  s = Math.floor(s);
  s /= 100;
  return s;
}

function moveTxUp(uIndex) {
  // const upPrefs = GatherAxs.Tx.MoveTx();
  // upPrefs.payload = { uIndex: uIndex, txIndex: txMoveRow };
  // dispatch(upPrefs);
  // var i = txMoveRow;
  // if (i >= 1) {
  //   doMoveTxRow(i - 1);
  // }
}

function getTheSubTx(tx) {
  var blankTx = { charges: {}, payments: {} };

  var keys = Object.keys(tx);
  var chg = 0;
  var pay = 0;
  blankTx['bounced']=false;
  blankTx['isStartBal']=false;
  keys.forEach((k) => {

    if (k === 'mSeq') {
      blankTx['mSeq'] = tx.mSeq;
    } else if (k === "m") {
      blankTx["m"] = tx.m;
    } else if (k === "id") {
      blankTx["id"] = tx.id;
    } else if (k === "rNum") {
      blankTx["rNum"] = tx.rNum;
      blankTx["isStartBal"]=tx.rNum==="Starting Balance";
    } else if (k === "amt") {
      if (tx.amt !== 0) {
        blankTx.payments["amt"] = tx.amt;
        pay += tx.amt;
      }
    } else if (k === "sb") {
      if (tx.sb > 0) {
        blankTx.payments["sb"] = tx.sb;
        pay += tx.sb;
      } else if (tx.sb < 0) {
        blankTx.charges["sb"] = tx.sb;
        chg += tx.sb;
      }
    } else if (k === "f" && tx.f !== 0) {
      blankTx.charges["f"] = tx.f;
      chg += tx.f;
    } else if (k === "r" && tx.r !== 0) {
      blankTx.charges["r"] = tx.r;
      chg += tx.r;
    } else if (k === "b" && tx.b !== 'false' && tx.b !== false && tx.b !== 0) {
      if (tx.b === true || tx.b === 1 || tx.b === 'true') {
        blankTx.charges["b"] = tx.amt;
        chg += tx.amt;
        // console.log(tx.b + "  found bounce");
        // console.log(tx.id);
        blankTx['bounced']=true;
      }
    } else if (k === "lmr" && tx.lmr !== 0) {
      blankTx.charges["lmr"] = tx.lmr;
      chg += tx.lmr;
    }
  });
  blankTx["chg"] = chg;
  blankTx["pay"] = pay;
  blankTx["subBal"] = pay - chg;
  return blankTx;
}
function sortTheTxs(txs) {


  const keys = Object.keys(txs);

  keys.sort((a, b) => {
    var dataA = txs[a].dataTx;
    var dataB = txs[b].dataTx;

    if (dataA.m < dataB.m) {
      return -1;
    }
    if (dataB.m < dataA.m) {
      return 1;
    }
    if (dataA.rNum?.substring(0, 12).toLowerCase() === "starting bal") {
      return -1;
    }
    if (dataB.rNum?.substring(0, 12).toLowerCase() === "starting bal") {
      return 1;
    }

    var rentComp = compKeyVal(dataA, dataB, "r");
    if (rentComp !== 0) {
      return rentComp;
    }
    var lmrComp = compKeyVal(dataA, dataB, "lmr");
    if (lmrComp !== 0) {
      return lmrComp;
    }
    var fComp = compKeyVal(dataA, dataB, "f");
    if (fComp !== 0) {
      return fComp;
    }
    var bComp = compKeyVal(dataA, dataB, "b");
    if (bComp !== 0) {
      return bComp;
    }
    var rNumA = dataA.rNum && dataA.rNum.length > 0 ? dataA.rNum : "";
    var rNumB = dataB.rNum && dataB.rNum.length > 0 ? dataB.rNum : "";
    if (rNumA < rNumB) {
      return -1;
    } else if (rNumA > rNumB) {
      return 1;
    }
    return 0;
  });
}
function groupTheTxs(txs) {
  const keys = Object.keys(txs);
  const monthDict = {};
  const monthKeys = [];
  keys.forEach((k, i) => {
    var m = txs[k].shadowTx.m;
    if (monthKeys.includes(m)) {
      monthDict[m].push(txs[k]);
    } else {
      monthKeys.push(m);
      monthDict[m] = [];
      monthDict[m].push(txs[k]);
    }
  });
  return monthDict;

}

function orderTheMonth(txsMonth) {
  txsMonth.sort((a, b) => {
    const aDict = a.shadowTx;
    const bDict = b.shadowTx;
    if (aDict.pay !== 0 && bDict.pay === 0) { return 1; }
    if (aDict.pay === 0 && bDict.pay !== 0) { return -1; }
    if (aDict.chg !== 0 && bDict.chg === 0) { return -1; }
    if (aDict.chg === 0 && bDict.chg !== 0) { return -1; }
    if(aDict.isStartBal===true&&bDict.isStartBal===false){return -1;}
    if(aDict.isStartBal===false&&bDict.isStartBal===true){return 1;}
    if (aDict.charges?.f !== 0 && bDict.charges?.b !== 0) { return 1; }
    if (aDict.charges?.b !== 0 && bDict.charges?.f !== 0) { return -1; }
    if (aDict.chg !== 0 && bDict.chg !== 0) {
      if (aDict.charges?.sb > 0) { return -1 }
      if (bDict.charges?.sb > 0) { return 1 }
      if (aDict.charges?.r > 0) { return -1 }
      if (bDict.charges?.r > 0) { return 1 }
      if (aDict.charges?.lmr > 0) { return -1 }
      if (bDict.charges?.lmr > 0) { return 1 }
      if (aDict.charges?.f > 0) { return -1 }
      if (bDict.charges?.f > 0) { return 1 }
      if (aDict.charges?.b > 0) { return -1 }
      if (bDict.charges?.b > 0) { return 1 }
      return 0;
    }
    if (aDict.payments?.sb === 0) {
      if (bDict.payments?.sb === 0) {
        return 0;
      } else if (bDict.payments?.sb > 0) {
        return -1;
      }
      else {
        return 1;
      }
    }
    if (bDict.payments?.sb === 0) { return 1 }
    if (bDict.payments?.sb === 0) {
      return 0;
    } else if (bDict.payments?.sb > 0) {
      return -1;
    }
    else {
      return 1;
    }
  })
}


function orderTheTxs(txs) {

  const monthKeys = Object.keys(txs);
  monthKeys.sort();
  const regroupedDict = {};

  monthKeys.forEach((k) => {
    orderTheMonth(txs[k])
    regroupedDict[k]=txs[k];
  });
  var runBal = 0;
  monthKeys.forEach((k,i)=>{

     var mkArray = regroupedDict[k];
     regroupedDict[k]['mStartBal']=runBal;
     regroupedDict[k]['m']=Number(k);
     regroupedDict[k]['z']=regroupedDict[k].length;
     var mSum = 0;
     var mPay = 0;
     var mPayments =[];
     var mCharges = [];
     var mCharge = 0;
     var mPayCount = 0;
     var mChargeCount =0;

     mkArray.forEach((k,i)=>{
        k['isStartBal']=k.shadowTx.isStartBal;
        k['runBal']=k.shadowTx.subBal+runBal;
        k['i']=i;
        runBal+=k.shadowTx.subBal;
        mPay+=k.shadowTx.pay;
        mCharge+=k.shadowTx.chg;
        var id =k.shadowTx.id;
        mSum+=k.shadowTx.subBal;
        var tempPayKeys = Object.keys(k.shadowTx.payments);
        var tempChargeKeys = Object.keys(k.shadowTx.charges);
        mPayCount+=tempPayKeys.length;
        mChargeCount+=tempChargeKeys.length;
        tempPayKeys.forEach((pk)=>{})
        k['payZ']=tempPayKeys.length;
        k['chgZ']=tempChargeKeys.length;
        tempPayKeys.forEach((ck)=>{  mPayments.push({txID:id,type:ck,amount:k.shadowTx.payments[ck],dx:k.shadowTx.rNum,wasBounce:k.shadowTx.bounced})})
        tempChargeKeys.forEach((ck)=>{ mCharges.push({txID:id,type:ck,amount:k.shadowTx.charges[ck],dx:k.shadowTx.rNum})})
     })
     regroupedDict[k]['mCharge']=mCharge;
     regroupedDict[k]['mPay']=mPay;
     regroupedDict[k]['mNetBal']=mSum;
     regroupedDict[k]['mEndBal']=runBal;
     regroupedDict[k]['payZ']=mPayCount;
     regroupedDict[k]['chgZ']=mChargeCount;
     regroupedDict[k]['mPayments']=mPayments;
     regroupedDict[k]['mCharges']=mCharges;
  })


  // runBal = addBal(runBal, txs[k].shadowTx.subBal);
  // txs[k].shadowTx["runBal"] = runBal;
  // txs[k]['order'] = i;
  // txs[k]['deltaOrder'] = 0;
  return regroupedDict;
};

