import React, { useEffect, useState } from "react";
import ResetBtn from "./ResetBtn";
import SaveBtn from "./SaveBtn";
import { useSelector } from "react-redux";
import NameBox from "./NameBox";


function MakeUnitName(isApt, name) {
  return (isApt ? "Apt. " : "Unit ") + name.substring(0, 2);
}

const Name = ({ index }) => {

  const [nameStr, setName] = useState("");

  const name = useSelector((state) => state.units.items[index].shadow.name);
  const id = useSelector((state) => state.units.items[index].id);
  const isApt = useSelector((state) => state.units.items[index].shadow.isApt);
  const edit = useSelector((state) => state.uo.edit.id);
  const height = useSelector((state) => state.ui.h.line);
  const justData = useSelector((state) => state.uo.col.justData['name']);
  const colWidths = useSelector((state) => state.uo.col.widthsInputs);
  const colWidth = colWidths['name'];

  useEffect(() => {
    setName(() => { return MakeUnitName(isApt, name) })
  }, [name, isApt]);



  return (
    <div className={`flex flex-col ${height}  ${colWidth}  `}>
       <div className={`flex flex-row    `}>
      <ResetBtn className='w-1/5' index={index} />

        <div className={`flex flex-row justify-center ${colWidth}   `}>
        <div className={`   ${(edit === id) ? "collapse w-0" : "visible   text-center"  } `}>
          {nameStr}
        </div>
        <div className={`text-center  ${(edit === id) ? "visible w-3/5 text-center" : "collapse w-0"} text-crimson-900`}
        >
          <NameBox index={index} />
        </div>
        </div>


      <SaveBtn index={index} /></div></div>
  );
};

export default Name;
