
export const GetOrderedDict = (data) => {
    const theKeys = Object.keys(data);
    const theValues = [];
    theKeys.forEach((k) => { theValues.push(data[k].index) });
    const theValues2 = [...theValues];
    theValues2.sort();
    const theKDict = {};
    const theKK = [];
    theValues2.forEach((v) => theKDict[v] = theKK.push(theKeys[theValues.indexOf(v)]));
    return theKK;
  }
  
  
  
  
 export const GetOddsDict = (orderedArray, rawDict) => {
    const showingDict = {};

  var i=0;
    orderedArray.forEach((k) => { if (rawDict[k].show) { showingDict[k]=i++ }else{showingDict[k]=-1;} });




    return showingDict;
  }
  
  export function getRowOddsDict(data) {
    const oddsDict = [];
    var zActive = 0;
    var zInactive = 0;
    var zTrash = 0;
    Object.keys(data).forEach((k, i) => {
  
      var act = data[k].shadow.active;
  
      oddsDict.push({
        active: (act === 1) ? zActive++ : -1,
        inactive: (act === 2) ? zInactive++ : -1,
        trash: (act === 0) ? zTrash++ : -1,
        all: i
      });
    })
    return oddsDict;
  }