import { createSlice } from "@reduxjs/toolkit"; 
import txTicketInit from './init'
import txTicketRdx from './rdx'

 const txTicketSlice = createSlice({
  name: "txTicket",
  initialState:txTicketInit(),
  reducers: {
    ...txTicketRdx
  } ,
  extraReducers: (builder) => {
      },
});

export default txTicketSlice.reducer;
