import { useEffect, useRef  } from "react";
import { useDispatch, useSelector } from "react-redux";
 
import { fetchUnits } from "./slices/unitSlice/unitFetchRdx";  
import { fetchUOps } from "./slices/uoSlice/uoRdxs/uoFetchRdx";
import { GetAllJSON, getTheData } from "./exportJSON";
import * as data from '../rawData.json';




/**
 * Will load the store needed for functioning. That consists of the base units (without txs).
 * It will only render the children once the store is loaded.
 * @param {'*'} param0 
 * @param {string} className provides the tailwind className info if any
 * @param {Node} children the child nodes if any
 * @returns {Provider} provider this wraps the children
 */
function LoadStore({ className, children }) {
  const dispatch = useDispatch();
  const unitPending = useSelector((state) => state.units.pending);  
  const calledFetch = useRef(false); 
  const unitOptsPending = useSelector((state) => state.uo.pending);  
 

  
  useEffect(() => {
   if(!calledFetch.current){ 
      calledFetch.current=true;
      // GetAllJSON().then((dd)=>{ 
        // jsonRef.current=dd; 
        dispatch(fetchUnits());
        dispatch(fetchUOps()); 
      // })
      
   }
      return;
    
    
  }, [dispatch ]);

 
  if(!calledFetch.current||unitPending||unitOptsPending){
  return <div className="text-xl font-bold text-bilbao">Need to call units...{calledFetch.current?"called units":"have not called units"} and {unitPending?"is pending":"is not pending"} and {unitOptsPending?" opts is pending":"opts is not pending"}.</div>

  }else{  
  return (
    <div className={className}>
      {children}
    </div>
  );
}}

export default LoadStore;
