const MobileHomeIcon = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
        aria-hidden="true"
      >
        <path
          fill="#738585"
          d="M 18.228516 11.130859 L 1.7714844 11.130859 L 2 18 L 18 18 L 18.228516 11.130859 z "
          fillRule="evenodd"
        />
        <path
          fill="#878e92"
          d="M 18.714844,10.595703 H 1.2851561 l 0.018577,0.535156 H 18.696267 Z"
          fillRule="evenodd"
        />
        <path
          fill="#a0b1a7"
          d="M 1 2.5 L 1 3 L 1.5 3 L 1.7539062 10.595703 L 18.246094 10.595703 L 18.5 3 L 19 3 L 19 2.5 L 1 2.5 z M 3.5 4 L 16.5 4 L 16.333984 9 L 3.6660156 9 L 3.5 4 z "
          fillRule="evenodd"
        />
        <path
          fill="#292324"
          d="M 19,2.5 H 1 l 0.019185,0.2474576 h 17.96163 z"
          fillRule="evenodd"
        />
        <path
          fill="#9496c5"
          d="M 3.5,4   3.56219,9.0019841 3.6660156,9 3.7698413,8.99802 16.333984,9 16.333984,9 L 16.5,4 Z"
          fillRule="evenodd"
        />
      </svg>
    );
  };

  export default MobileHomeIcon