import { ArrowPathIcon } from "@heroicons/react/20/solid";
import React, {   useEffect } from "react";
import {   useDispatch, useSelector } from "react-redux";
import txTicketActs from "../../../store/slices/txTicket/acts";

const ResetBtn = (props) => {

  const txID = props.id.substring(0,props.id.length-4);
  const mID  = useSelector((state) => state.txTicket.txMonthXRef[txID][0]);
  const typeNum = props.bounce?3:(props.type==="pay"?1:props.type==='chg'?2:0);
  const txOldSpot  = useSelector((state) => state.txTicket.txOrgMonth[txID]);
  const txNewSpot  = useSelector((state) => state.txTicket.txNewMonth[txID]);
  const oldMonth = txOldSpot?txOldSpot.mID:undefined;
  const newMonth = txNewSpot?txNewSpot.mID:undefined;
  const oldIndex = oldMonth?(typeNum===1?txOldSpot.payIndex:(typeNum===2?txOldSpot.chgIndex:typeNum===3?txOldSpot.bIndex:-1)):-2;
  const newIndex = newMonth?(typeNum===1?txNewSpot.payIndex:(typeNum===2?txNewSpot.chgIndex:typeNum===3?txNewSpot.bIndex:-1)):-2;
  const sameMonth = oldMonth?oldMonth===newMonth:false;
  const sameIndex = oldIndex===newIndex;
  const show = !(sameMonth&&sameIndex);
  const iconSize = useSelector((state) => state.ui.iconSize);
  const dispatch = useDispatch();
  const height = useSelector((state) => state.ui.h.line);
  function doClick(){
    if(show){




      dispatch(txTicketActs.resetTx()({ticketID:txID}))


    }
  }
  useEffect(() => {}, []);

  return (
    <div
      className={`flex flex-col justify-center ${height}  w-fit   z-48 ${show?'visible':'invisible'} `}
      onClick={() => {
        doClick();
      }}
    >
      <div className={`  ${iconSize}`}>
        <div
          className={` text-bilbao-700    }`}
        >
          <ArrowPathIcon />
        </div>
      </div>
    </div>
  );
};

export default ResetBtn;
