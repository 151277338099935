import React, { useContext, useEffect } from "react";

import UnitTblActions from '../../../../store/slices/uoSlice/uoFxs';
import FloppyICO from "../../../../assets/floppy";
import { useDispatch, useSelector } from "react-redux";
import EChange from "../../../../store/statics/EChange";

const SaveBtn = ({ index }) => {


  const dispatch = useDispatch();

  const id = useSelector((state) => state.units.items[index].id);
  const eChanged = useSelector((state) => state.units.items[index].eChanged);
  const edit = useSelector((state) => state.uo.edit.id);
  const shadow = useSelector((state) => state.units.items[index].shadow);

  const allItems = useSelector((state) => state.units.items );
  const height = useSelector((state) => state.ui.h.line);
  const iconSize = useSelector((state) => state.ui.iconSize);




  function GetQuadCount(count,v,i  ){
    var answer = [-1,-1,-1,i];
    answer[v]=count[v];
    count[v]++;
    return answer;
  }


  function updateTheOdds ( )  {
    var keys = Object.keys(allItems);
    var ii=[0,0,0,0];
    keys.forEach((k,i)=>{
      dispatch(UnitTblActions.saveAction()(allItems[i].id, i, {...allItems[i].shadow,odd:GetQuadCount(ii,allItems[k].shadow.active,i)},allItems[k].eChanged))
    })

  }





  const handleClick = () => {
    if(eChanged&EChange.active===0){
    dispatch(UnitTblActions.saveAction()(id, index, shadow, eChanged))
    }else
    {
      dispatch(UnitTblActions.saveAction()(id, index, shadow, eChanged))
      updateTheOdds();


    }
}

  const showMe = () => {
    if ((edit !== id) && (eChanged > 0)) { return 'visible'; } else { return 'invisible' }
  }

  useEffect(() => { }, []);

  return (
    <div
      className={`flex flex-col justify-center ${height}  w-fit  `}
      onClick={() => {
        handleClick();
      }}
    >
        <div className={`flex flex-col justify-center ${showMe()}   ${iconSize}`} >
          <FloppyICO />
        </div>
    </div>
  );
};

export default SaveBtn;
