

function txTicketInit() {
  return {
    txMonthXRef:{},
    txIDs:[],
    txData:{},
    monthIDs:[],
    monthXRef:[],
    txNewMonth:{},
    txOrgMonth:{},
  };
}
export default txTicketInit;
