import React from "react";

import Header from "./Header";
import { useSelector } from "react-redux";

const TableHead = () => {
  const colOrder = useSelector((state) => state.uo.col.order);

  return (
    <div
      className={`  bg-merlin-300   flex flex-row justify-normal select-none text-cod-gray-950   `}
    >
      {colOrder.map((k) => {
        return <Header name={k} key={k + "o"} />;
      })}
    </div>
  );
};

export default TableHead;
