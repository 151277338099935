import { createAction } from "@reduxjs/toolkit";
import { WriteUnitDataToCloud } from "../../cloudFx/UnitDb";






/**
 * @description This provides the Actions to use
 * for settings on the UnitTable
 */
const txsActs = {


  /**
 * @description This is used to set the Active level using
 * @param {number} a - This is the level 0 dead, 1 active, 2 asleep, 3 all
 * @param {Array} odds -This is the array of the odd indexed rows under the new a
 */
  takeUnitTxData( ) {
    return createAction('txs/addUnitTxData', function prepare(id,  data ) { return { payload: { id:id,  data:data  } } });
  },




}

export default txsActs