import React, {   useEffect  } from "react";
import { useDroppable } from "@dnd-kit/core";
import { useSelector } from "react-redux";
import GetCHEDateStr from "../../../functions/CHEDateStr";
import { DollarText } from "../../../functions/DollarText";
import { DroppableAddTx } from "./DroppableAddTx";

export function DroppableMonths(props) {
  const { isOver, setNodeRef } = useDroppable({
    id: props.id,
  });
  const blocksXRef = useSelector((state) => state.txRib.months.children);
  const monthsXRef = useSelector((state) => state.txRib.months.xRef);
  const monthID = props.id?.substring(0, props.id?.length - 4);
  const month = monthID && monthsXRef[monthID];

  const data = useSelector((state) => state.txTicket.txMonthXRef[monthID]);
  const guiPay =data.paySum;
  const guiChg = data.chgSum;
  const guiSubSum =data.subBal;
  const guiRunStart =data.startBal;
  const guiRunEnd =data.endBal;
  const guiPayStr = DollarText({
    value: guiPay ? guiPay : 0,
    digits: 15,
    cents: true,
  });
  const guiChgStr = DollarText({
    value: guiChg ? guiChg : 0,
    digits: 15,
    cents: true,
  });
  const guiSumStr = DollarText({
    value: guiSubSum ? guiSubSum : 0,
    digits: 15,
    cents: true,
  });
  const guiRunStartStr = DollarText({
    value: guiRunStart ? guiRunStart : 0,
    digits: 15,
    cents: true,
  });
  const guiRunEndStr = DollarText({
    value: guiRunEnd ? guiRunEnd : 0,
    digits: 15,
    cents: true,
  });

  useEffect(() => {}, [guiPay, guiChgStr,data.update]);

  function doAddPayTicket() {
    // addPayTicket(monthID)
  }
  function doAddChgTicket() {
    // addChgTicket(monthID)
  }

  return (

    <div
      ref={setNodeRef}
      className={`${
        isOver
          ? "bg-pelorous-800"
          : props.odd
          ? "bg-cod-gray-800"
          : "bg-cod-gray-700"
      } flex flex-row shrink-0 grow   w-177 h-fit z-0`}
    >
      <div className="flex flex-col">
        <div
          className={`${
            isOver
              ? "bg-pelorous-300/10"
              : props.odd
              ? "bg-merlin-400"
              : "bg-merlin-300"
          } flex flex-row shrink-0 grow-0  snap-start  w-177 h-8  z-10`}
        >
          <div className="font-bold text-left w-36">{GetCHEDateStr(month)}</div>

          <div className="flex flex-row w-12 h-12 p-0 m-0 text-merlin-300/50">



          </div>

          <div className="w-24 ml-12 text-right whitespace-pre bg-cod-gray-200/10">
            {guiRunStartStr}
          </div>
          <div className="w-24 ml-12 text-right whitespace-pre bg-cod-gray-200/10">
            {guiPayStr}
          </div>
          <div className="w-24 ml-12 text-right whitespace-pre bg-cod-gray-200/10">
            {guiChgStr}
          </div>

          <div className="w-24 ml-12 text-right whitespace-pre bg-cod-gray-200/10">
            {guiSumStr}
          </div>
          <div className="w-24 ml-12 text-right whitespace-pre bg-cod-gray-200/10">
            {guiRunEndStr}
          </div>
        </div>
        <div className="flex flex-col h-fit w-177">
          <div className="relative">{props.children}</div>
        </div>
      </div>
    </div>
  );
}
