import { ScissorsIcon } from "@heroicons/react/20/solid";
import UnitTblActions from '../../../../store/slices/uoSlice/uoFxs';
import { useDispatch, useSelector } from "react-redux";

const EditToggle = ({  index }) => {
 const dispatch = useDispatch();

  const id = useSelector((state) => state.units.items[index].id);
  const editID = useSelector((state) => state.uo.edit.id);
  const focusID = useSelector((state) => state.uo.focus.id);
  const focusIndex = useSelector((state) => state.uo.focus.index);
  const isEditing  = ()=>id===editID;
  const hasFocus  =focusIndex>-1;
  const isFocusing = focusID===id;

  const iconSize = useSelector((state)=>state.ui.iconSize);
  const handleClick=()=>{
    if(id===editID && (!hasFocus||(focusID===id))){
      dispatch(UnitTblActions.editAction()("",-1));
    }
    else if(!hasFocus||(focusID===id))
    {
      dispatch(UnitTblActions.editAction()(id,index));
    }
}

 return(<div className={`flex ${iconSize}  transition-all`}  onClick={() => {    handleClick();  }}>
  {getIcon(isEditing())}
</div>

)
  };


  export default EditToggle


  const getIcon=(isEditing)=>{
   if (!isEditing) {
      return (
        <ScissorsIcon className="transition-all duration-500 scale-95  rotate-90 text-cod-gray-500" />
      );
    } else  {
      return (
        <ScissorsIcon className="transition-all duration-500 scale-105   -rotate-45 text-cod-gray-900" />
      );
    }
  }