import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import TableRows from "./rows/TableRows";
import TableHead from "./headers/TableHead";
import { DndContext } from "@dnd-kit/core";
import TableRowsFocus from "./rows/TableRowsFocus";
import { useDispatch, useSelector } from "react-redux";
import DeepCopyTree from "../store/cloudFx/DeepCopyTree";
import txRibActs from "../store/slices/txRib/acts";
import txsActs from "../store/slices/txLibSlice/acts";
import { GetUnitTx } from "../store/cloudFx/UnitDb";
import { DraggableTicket } from "./txTbl/DnD/Draggable";
import { delayRunNoData } from "../functions/Timeout";
import TxTableSet from "./txTbl/TxTableSet";

export const TableAppCtx = createContext();


const FocusScreen = ({ device }) => {
  const dispatch = useDispatch();

  const focusID = useSelector((state) => state.uo.focus.id);
  const focusIndex = useSelector((state) => state.uo.focus.index);
  const tValues = useSelector((state) => state.txRib.gui.values);
  const tValueIDs = useSelector((state) => state.txRib.gui.valueIDs);
  const count = tValueIDs?.length ? tValueIDs?.length : 0;
  const hasFocus = focusIndex !== -1;
  const cz = useRef(0);
  const hasTxs = useSelector(
    (state) => hasFocus && state.txs.ids.includes(focusID)
  );

  const sortedData = useRef(false);
  const sortedFocus = useRef(null);
  const sortedZ = useRef(0);

  const knownKeys = [
    "r",
    "m",
    "pymt",
    "lmr",
    "subBal",
    "order",
    "seq",
    "id",
    "uv",
    "runBal",
    "b",
    "f",
    "rNum",
    "sb",
    "amt",
  ];
  function filterEntry(data) {
    var { m, id, lmr, r, b, f, rNum, sb, amt } = data;
    var screen = {};
    screen["m"] = m;
    screen["id"] = id;
    screen["pay"] = {};
    screen["chg"] = {};

    screen["bounced"] = false;
    var payZ = 0;
    var chgZ = 0;
    var paySum = 0;
    var chgSum = 0;
    var isRent = false;
    var isLMR = false;
    var isBounce = false;
    var isFee = false;
    let priority = 0;
    let isBoth = 0;

    if (!isNaN(lmr) && Number(lmr) !== 0) {
      screen.chg["lmr"] = Number(lmr);
      chgZ++;
      chgSum += Number(lmr);
      isLMR = true;
      priority = 4;
      isBoth |= 2;
    }
    if (!isNaN(r) && Number(r) !== 0) {
      screen.chg["r"] = Number(r);
      chgZ++;
      chgSum += Number(r);
      isRent = true;
      priority = 5;
      isBoth |= 2;
    }
    if (!isNaN(b) && b !== false) {
      if (b === true) {
        screen.chg["b"] = Number(amt);
        chgSum += Number(amt);
        chgZ++;
        isBounce = true;
        priority = 3;
        isBoth |= 3;
      }
    }
    if (!isNaN(f) && Number(f) !== 0) {
      screen.chg["f"] = Number(f);
      chgSum += Number(f);
      chgZ++;
      isFee = true;
      priority = 2;
      isBoth |= 2;
    }

    if (priority === 0 && Number(amt) > 0) {
      paySum += Number(amt);
      payZ++;
      screen.pay["0"] = Number(amt);
      isBoth |= 1;
    }

    if (rNum !== undefined) {
      if (rNum === "Starting Balance") {
      } else {
        if (isBounce) {
          screen["rNum"] = "Bounce " + rNum;
          screen["bounced"] = true;
        } else if (isFee) {
          screen["rNum"] = "Bounce Fee";
        } else {
          screen["rNum"] = rNum;
        }
      }
    } else {
      if (isRent) {
        screen["rNum"] = "Rent";
      } else if (isLMR) {
        screen["rNum"] = "Last Month";
      } else if (isFee) {
        screen["rNum"] = "Bounce Fee";
      }
    }

    if (screen.bounced) {
      priority = 3;
    }
    if (paySum === chgSum && Number(chgSum) === 0) {
      priority = -1;
    }
    screen["priority"] = priority;
    screen["chgZ"] = chgZ;
    screen["payZ"] = payZ;
    screen["chgSum"] = chgSum;
    screen["paySum"] = paySum;
    screen["both"] = isBoth;

    return screen;
  }
  function cleanEntries(txs) {
    var temp = {};
    var keys = Object.keys(txs);

    var months = [];

    keys.forEach((k) => {
      temp[k] = { dataTx: filterEntry({ ...txs[k].dataTx }), eChangedTx: 0 };
      if (!months.includes(temp[k].dataTx.m)) {
        months.push(temp[k].dataTx.m);
      }
    });
    months.sort();

    dispatch(txRibActs.setMonths()(months, focusID));

    keys.forEach((k) => {
      let isPay = temp[k].dataTx.payZ > 0;
      let isChg = temp[k].dataTx.chgZ > 0;
      if (isPay && isChg) {
        console.log("HOW? " + temp[k].dataTx.m);
      } else if (isPay) {
        dispatch(txRibActs.addPayment()(DeepCopyTree(temp[k].dataTx)));
      } else if (isChg) {
        dispatch(txRibActs.addCharge()(DeepCopyTree(temp[k].dataTx)));
      }
      if (temp[k].dataTx.bounced) {
        dispatch(txRibActs.addBounce()(DeepCopyTree(temp[k].dataTx)));
      }
    });

    return temp;
  }

  const handleUnitTxs = () => {
    if (hasFocus && focusID !== sortedFocus.current) {


      sortedFocus.current = focusID;
      GetUnitTx(focusID).then((txs) => {
        var cleanTxs = cleanEntries(txs);
        var data = {
          txs: cleanTxs,
          dirty: true,
          dirtyList: [],
          toldUnit: false,
        };
        var key = focusID;
        var dataTake = { uID: focusID, txs: cleanTxs };
        dispatch(txRibActs.setUnitID()(focusID, dataTake));
        dispatch(txsActs.takeUnitTxData()(key, data));
        dispatch(txRibActs.guiTxs()(cleanTxs));
        dispatch(txRibActs.guiInit()());

        let tTemp = {};
        Object.keys(tValues).forEach((tV) => {
          let tItem = tValues[tV];
          tTemp[tV] = [undefined, undefined];
          if (tItem[0] !== undefined) {
            tTemp[tV][0] = (
              <DraggableTicket
                id={tItem[0].id}
                amt={tItem[0].amt}
                rNum={tItem[0].rNum}
                type={"pay"}
              />
            );
          }
          if (tItem[1] !== undefined) {
            tTemp[tV][1] = (
              <DraggableTicket
                id={tItem[1].id}
                amt={tItem[1].amt}
                rNum={tItem[1].rNum}
                type={"chg"}
              />
            );
          }
        });
      });
    }
    if (sortedZ.current !== focusID) {

    }
  };

  function foundID() {
    let found = null;

    firstTx: for (var i = 0; i < count; i++) {
      var tempValID = tValueIDs[i];
      var tempVal = tValues?.[tempValID];
      var tPay = tempVal && tempVal[0] !== null ? tempVal[0] : null;
      var tChg = tempVal && tempVal[1] !== null ? tempVal[1] : null;
      if (tPay) {
        found = tPay.uID;
        break firstTx;
      }
      if (tChg) {
        found = tChg.uID;
        break firstTx;
      }
    }
    sortedZ.current = found;
    sortedData.current = count > 0 && sortedZ.current === focusID;
    cz.current = cz.current + 1;

    return found;
  }

  useEffect(() => {
    handleUnitTxs();
  }, [
    hasTxs,
    hasFocus,
    sortedData,
    sortedFocus.current,
    count,
    sortedZ.current,
    cz.current,
  ]);

  return (
    <TableAppCtx.Provider value={{}}>
      {device === "desktop" ? (
        <div className={`p-10 bg-merlin-400 bg-blend-overlay`}>
          <div className="flex flex-col">
            <TableHead />
            <TableRows device={device} />
            <TableRowsFocus device={device} />
          </div>

          {hasTxs  && (
            <div className="flex-init w-fit     ">
              <DndContext autoScroll={false}>
             <TxTableSet device={device} />
              </DndContext>
            </div>
          )}
        </div>
      ) : (
        <></>
      )}
    </TableAppCtx.Provider>
  );
};

export default FocusScreen;
