import React from "react";
import TableRow from "./TableRow";
import { useSelector } from "react-redux";

const TableRows = ({ device }) => {
  
  const height = useSelector((state) => state.ui.h.line);
  const activeMode = useSelector((state) => state.uo.active);
  const theKeys = useSelector((state) => state.units.items);
  const focusIndex = useSelector((state) => state.uo.focus.index); 

  const theShadows = () => Object.values(theKeys).map((n) => n.shadow);

 
  const hasFocus = focusIndex !== -1;
  const allActive = activeMode === 3;

  function getMeActive(u){
    return allActive||(u.active === activeMode);
  }
 
  if(hasFocus){return <></>}
  return (
    <div className={`flex flex-col shrink-0 grow ${hasFocus&&"collapse"}`}>
      {theShadows().map((u, i) => {
        return (getMeActive(u)&&
          <div
            className={`flex col-span-1    w-full`}
            key={u.id}
          > 
            <TableRow index={i}   height={height} />
          </div>
        );
      })}
    </div>
  );
};

export default TableRows;
