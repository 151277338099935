
const ApartmentIcon = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
        aria-hidden="true"
      >
        <path
          fill="#738585"
          d="M 10.119141 1.4277344 L 0.6484375 8.7207031 L 2.3554688 8.7207031 L 2.3554688 18.273438 L 4.5175781 18.273438 L 10.119141 18.273438 L 15.720703 18.273438 L 17.882812 18.273438 L 17.882812 8.7207031 L 19.589844 8.7207031 L 10.119141 1.4277344 z "
          fillRule="evenodd"
        />
        <path
          fill="#000000"
          d="M 10.119141,1.4277344 0.6484375,8.7207031 H 2.3554688 L 10.15873,2.609592 17.882812,8.7207031 h 1.707032 z"
          fillRule="evenodd"
        />
      </svg>
    );
  };


  export default ApartmentIcon 