
import { Routes, Route } from 'react-router-dom';
import { AuthProvider } from './auth/Context';
import SignIn from './auth/SignIn';
import NotFound from './NotFound'
import MobileDetect from 'mobile-detect';
import Dashboard from './components/dash/Dashboard'; 

function GetMobile() {
   var type = new MobileDetect(window.navigator.userAgent).ua;
   type = type.toLowerCase();

   var tt = type.match(/(?<mobile>(android|mobile))|(?<desktop>(windows|win64|x64))/ui);

   return tt.groups.mobile !== undefined;

}



const App = ({debug}) => {
   var isMobile = GetMobile();
   // console.log((isMobile)?'Is mobile device':'Is desktop browser.');
   return <> 
  {debug&& <div className="w-16 font-bold text-sunshade-400 bg-salem-300">App Main</div>} 
      <AuthProvider >
         {isMobile ? 
               <Routes>
                  <Route path='/' element={<SignIn />} />
                  <Route path='/dashboard' element={<Dashboard device={'mobile'}/>} />
                  <Route path='*' element={<NotFound />} />
               </Routes> 
            : 
               <Routes>
                  <Route path='/' element={<SignIn />} />
                  <Route path='/dashboard' element={<Dashboard device={'desktop'}/>} />
                  <Route path='*' element={<NotFound />} />
               </Routes> 
         }
      </AuthProvider></>
}

export default App;








