import { createSlice } from "@reduxjs/toolkit"; 
import txRibInit from './init'
import txRibRdx from './rdx'

 const txRibSlice = createSlice({
  name: "txRib",
  initialState:txRibInit(),
  reducers: {
    ...txRibRdx
  } ,
  extraReducers: (builder) => {
      },
});

export default txRibSlice.reducer;
