import React, { useContext, useEffect, useState } from 'react'
import { Navigate, useNavigate } from "react-router-dom";
import GoogleButton from '../assets/GoogleButton'
import { AuthCtx } from './Context';
import SVGLogo from '../assets/SVGLogo';

function SignIn() {

    const { doSignIn, signedIn } = useContext(AuthCtx);

    useEffect(() => { }, [signedIn])
    return (

        <>
            {signedIn ? <Navigate to='/dashboard' /> :
                <div className="grid w-screen h-screen place-items-center">
                    <div className="grid justify-items-center grid-cols-1    bg-waikawa-gray-400   shadow-inner shadow-waikawa-gray-950   aspect-[3/4]" >
                        <div className="relative flex items-center justify-center w-full mt-10 h-30">  <SVGLogo /></div>

                        <div className="flex py-1 max-w-[80%] min-w-[80%] items-center justify-center">
                            <button className="flex justify-center object-contain" onClick={()=>{doSignIn()}} >
                                <GoogleButton /></button></div>

                    </div></div>} </>

    );

}

export default SignIn;

