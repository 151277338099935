import { createAction } from "@reduxjs/toolkit";

const txTicketActs = {
  setInitData() {
    return createAction("txTicket/setTxMonthIDs", function prepare({monthIDs,monthNums,ticketTxs}) {
      return { payload: { monthIDs: monthIDs,monthNums:monthNums,txs:ticketTxs } };
    });
  },
  moveTx() {
    return createAction("txTicket/moveTx", function prepare({fromMonthIndex,toMonthIndex,ticketID,ticketTypeNum,toIndex}) {
      return { payload: { fromMonthIndex:fromMonthIndex,toMonthIndex:toMonthIndex,ticketID:ticketID,ticketTypeNum:ticketTypeNum,toIndex:toIndex } };
    });
  },
  resetTx() {
    return createAction("txTicket/restore", function prepare({ticketID}) {
      return { payload: {  ticketID:ticketID   } };
    });
  },
};

export default txTicketActs;
