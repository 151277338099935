import React, { useEffect, useState } from 'react';
import { DndContext } from '@dnd-kit/core';
import { Droppable } from './Droppable';
import { Draggable } from './Draggable';
import { DroppableBig } from './DroppableBig';

function App() {
  const [location, setLocation] = useState({ });
  const [values, setValues] = useState({ });
  const blocks = ['A','B','C','D'];

  const keys = [
    {id:'PS',location:'A',gender:'m'},
    {id:'ES',location:'A',gender:'f'},
    {id:'VS',location:'B',gender:'f'},
    {id:'MS',location:'C',gender:'m'},
    {id:'SS',location:'D',gender:'m'},
    {id:'BS',location:'D',gender:'m'},
    {id:'YOGI',location:'D',gender:'m'},
    {id:'RUBY',location:'D',gender:'f'},
    {id:'ROSY',location:'D',gender:'f'},
  ];

  function draggableMarkup(str,gender)
  { return  <Draggable v={str} gender={gender}>
        {str}
    </Draggable>
  };

  function initValues ( ) {
      var vv = {};
      var dd = {};
      keys.forEach((k)=>{
          vv[k.id]=draggableMarkup(k.id,k.gender);
          dd[k.id]=k.location;
      })
    setValues(()=>{return vv;})
    setLocation(()=>{return {...dd}})
  }

  useEffect(()=>{
    if(Object.keys(values).length===0){
      initValues();
    }
  },[values])

  if (values.length===0){return "..."}
  return (
    <DndContext onDragEnd={handleDragEnd} onDragStart={handleDragStart}>
        <div >
        {blocks.map((b,i)=>
        <DroppableBig id={b} odd={i%2===1} >

         {keys.map((k,i)=>{return location[k.id]===b && values[k.id]  })}

          <Droppable id={b+'A'} odd={true}>
                {keys.map((kA,iA)=>{  return location[kA.id]===(b+'A') && values[kA.id]  })}
          </Droppable>

          <Droppable id={b+'B'} odd={false}>
                {keys.map((kB,iB)=>{ return location[kB.id]===(b+'B') && values[kB.id]  })}
          </Droppable>
          </DroppableBig>)}
        </div>

    </DndContext>
  );
  function handleDragStart(event) {
    console.log('Drag started');
  }
  function handleDragEnd(event) {
    if (event.over ) {
      var tempLocation = {...location};

      var item = event.active.id;

      var gender=((keys.find((a)=>(a.id===item)).gender)==='m'?'A':'B');

      tempLocation[event.active.id]=event.over.id.substring(0,1)+gender;

      setLocation(()=>{return tempLocation});
    }
  }
}

export default App;