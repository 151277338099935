import { MakeArrayRange } from "./ArrayMakeRange";

 function GetCHEDateStr(m, rev = false) {
  var mm = m; 
  if (isNaN(mm)) { mm = 13; };
  const yy = 2018 + Math.floor((mm-1) / 12);
  var months = (mm - 1) % 12;
  var date = new Date(yy, months, 1);
  var strMonth = date.toLocaleString('en-US', { month: 'short' });
  strMonth = strMonth.toUpperCase();
  return (rev === true ? strMonth + " " + yy : yy + " " + strMonth);
}

export default GetCHEDateStr;

export const ThisYear = new Date(Date.now()).getFullYear();
export const ThisMonth = new Date(Date.now()).getMonth();
export const CHEMonth = ((ThisYear-2018)*12)+ThisMonth+1;
export const ValidYears = MakeArrayRange(2018, ThisYear);