import { createSlice } from "@reduxjs/toolkit"; 
import txoLibInit from './init'
import txoLibRdx from './rdx' 

 const txoLibSlice = createSlice({
  name: "txo",
  initialState:txoLibInit,
  reducers: {
    ...txoLibRdx
  } ,
  extraReducers: (builder) => {
        
      },
});

export default txoLibSlice.reducer;
