import { createAsyncThunk } from "@reduxjs/toolkit";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { db } from "../../../../auth/fbase";
import { GetOddsDict, GetOrderedDict } from "../../common/dictOps";

export const fetchUOps = createAsyncThunk("uo/fetch", async () => {
  const uPrefs = await GetPrefColData();
  return uPrefs;
});

async function GetPrefColData() {
  var theDoc = await getDoc(doc(db, "prefs", "colHideZ"));
  var data = theDoc.data();
  if (Object.keys(data).length === 0) {
    data = DefaultColumnPrefsDict();
    var v = await WriteColData({ ...data });
  }
  return CleanThePrefsDict(data);
}

function WriteColData(prefData) {
  setDoc(doc(db, "prefs", "colHideZ"), prefData).then(() => {});
}

export function WriteUpdateColData(prefData) {

  const rData = CleanThePrefsDict(prefData);
  setDoc(doc(db, "prefs", "colHideZ"), prefData).then(() => {});
  return rData;
}

export const DefaultColumnPrefsDict = () => {
  const dict = {};
  dict["name"] = { index: 0, show: true };
  dict["active"] = { index: 1, show: true };
  dict["occupant"] = { index: 2, show: true };
  dict["rent"] = { index: 3, show: true };
  dict["startMonth"] = { index: 4, show: true };
  dict["startBal"] = { index: 5, show: true };
  dict["endRMonth"] = { index: 6, show: true };
  dict["endRBal"] = { index: 7, show: true };
  return dict;
};
const DefaultTitlesDict = () => {
  const dict = {};
  dict["name"] = "#";
  dict["active"] = "";
  dict["occupant"] = "Occupant";
  dict["rent"] = "Rent";
  dict["startMonth"] = "Start Mo.";
  dict["startBal"] = "Start Bal.";
  dict["endRMonth"] = "End Mo.";
  dict["endRBal"] = "End Bal.";
  return dict;
};

function createWithMax(sz, w) {
  return (sz + "w-" + w).replace(/\s/g, "");
}
function createW(w, wMD, wLG) {
  return (
    "w-" +
    w +
    " " +
    createWithMax("md:", wMD) +
    " " +
    createWithMax("lg:", wLG) +
    " "
  );
}

const DefaultWidthsDict = () => {
  const dict = {};
  dict["name"] = "w-17 md:w-32 lg:w-36 ";
  dict["active"] = "w-24 md:w-28 lg:w-32";
  dict["occupant"] = "w-48 md:w-64 lg:w-72";
  dict["rent"] = "w-24 md:w-24 lg:w-32";
  dict["startMonth"] = "w-24 md:w-30 lg:w-36";
  dict["startBal"] = "w-36 md:w-36 lg:w-36";
  dict["endRMonth"] = "w-24 md:w-28 lg:w-36";
  dict["endRBal"] = "w-32 md:w-36 lg:w-48";

  return dict;
};

const DefaultWidthsInputDict = () => {
  const dict = {};
  dict["name"] = "w-16 md:w-31 lg:w-35";
  dict["active"] = "w-23 md:w-27 lg:w-31";
  dict["occupant"] = "w-47 md:w-63 lg:w-71";
  dict["rent"] = "w-23 md:w-23 lg:w-31";
  dict["startMonth"] = "w-23 md:w-29 lg:w-35";
  dict["startBal"] = "w-35 md:w-35 lg:w-35";
  dict["endRMonth"] = "w-23 md:w-27 lg:w-35";
  dict["endRBal"] = "w-31 md:w-35 lg:w-47";
  return dict;
};



const DefaultJustDataDict = () => {
  const dict = {};
  dict["name"] = "justify-center";
  dict["active"] = "justify-center";
  dict["occupant"] = "justify-start";
  dict["rent"] = "justify-end";
  dict["startMonth"] = "justify-center";
  dict["startBal"] = "justify-end";
  dict["endRMonth"] = "justify-center";
  dict["endRBal"] = "justify-end";
  return dict;
};

function CleanThePrefsDict(data) {
  const dictData = { ...data };
  const dict = GetOrderedDict(dictData);
  const odds = GetOddsDict(dict, dictData);
  return {
    colPrefs: dictData,
    colOrder: dict,
    colOdds: odds,
    colWidths: DefaultWidthsDict(),
    colWidthsInputs: DefaultWidthsInputDict(),
    colTitles: DefaultTitlesDict(),
    colJustifyData: DefaultJustDataDict(),
  };
}
