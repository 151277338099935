import React, { useContext, useEffect } from "react"; 
 
import { useSelector } from "react-redux";
import GetCHEDateStr from "../../../../functions/CHEDateStr";

const StartMonth = ({ index }) => { 

  const id = useSelector((state) => state.units.items[index].id);
  const startMonth = useSelector(
    (state) => state.units.items[index].shadow.startMonth
  );

  const colWidth = useSelector((state) => state.uo.col.widths.startMonth);
  const justCol = useSelector((state) => state.uo.col.justData.startMonth);
  const edit = useSelector((state) => state.uo.edit.id);

  const h = useSelector((state) => state.ui.h);


  useEffect(() => {}, []);

  return (
    <div
      className={`flex-none relative ${justCol} shrink ${h.line} text-center  ${ startMonth} ${colWidth} `}
    > 
        {GetCHEDateStr(startMonth)}
    </div>
  );
};

export default StartMonth;
