
import React from "react";
import IconActive from "./ActiveToggle";
import AptToggle from "./AptMblToggle";
import EditToggle from "./EditToggle";
import FocusToggle from "./FocusToggle";
import { useSelector } from "react-redux";


const QuadBtn = ({ index }) => {

  const colWidth = useSelector((state) => state.uo.col.widthsInputs.active);
  const height = useSelector((state) => state.ui.h.line);


  return (
    <div className={`flex flex-col shrink-0 grow-0  justify-center ${height}`}>
      <div className={`flex flex-row ${colWidth} justify-evenly `}>

        <div className="flex flex-col justify-center h-full">
          <FocusToggle index={index} />
        </div>

        <div className="flex flex-col justify-center h-full">

          <IconActive index={index} />
        </div>

        <div className="flex flex-col justify-center h-full">

          <AptToggle index={index} />
        </div>
        <div className="flex flex-col justify-center h-full">
          <EditToggle index={index} />
        </div>
      </div>
    </div>
  );
};

export default QuadBtn;
