import { QuestionMarkCircleIcon } from "@heroicons/react/20/solid";
import ApartmentIcon from "./Icons/AptIcon";
import MobileHomeIcon from "./Icons/MHIcon";

import UnitTblActions from '../../../../store/slices/uoSlice/uoFxs';

import EChange from '../../../../store/statics/EChange';
import { useDispatch, useSelector } from "react-redux";

 
const AptToggle = ({ index }) => {


  const id = useSelector((state) => state.units.items[index].id);
  const eChanged = useSelector((state) => state.units.items[index].eChanged);
  const isAptOrg = useSelector((state) => state.units.items[index].data.isApt);
  const isApt = useSelector((state) => state.units.items[index].shadow.isApt);
  const isEditing = useSelector((state) => state.uo.edit.id===state.units.items[index].id);
  const iconSize = useSelector((state)=>state.ui.iconSize);


  const dispatch = useDispatch();

  const handleClick= () => {
    if(isEditing){
    var toggle = !isApt;
    var eChange = eChanged;
    eChange|=EChange.isApt;
    if(toggle===isAptOrg){
      eChange^=EChange.isApt;
    }
    dispatch(UnitTblActions.editPartAction()(id, index, {isApt:toggle},eChange));
       
    }
  }


  return (

      <div className={`flex  flex-col justify-center `}  onClick={() => { handleClick() }}>

      <div className={`${ iconSize}`}>{getIcon(isApt)}</div>

      </div>



  )
};

export default AptToggle


function getIcon(isApt){

  if (isApt === false) {
    return <MobileHomeIcon className={`  text-waikawa-gray-800`} />;
  } else if (isApt === true) {
    return <ApartmentIcon className={`   text-meteor-900 `} />;
  } else {
    return <QuestionMarkCircleIcon className="" />;
  }
}

