
import { initializeApp } from "firebase/app";
import {getAuth  } from 'firebase/auth'
import {getFirestore} from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyALm8IKKAwe-t1ta_5poJO18CAlui98ono",
  authDomain: "chewy.chewareham.com",
  projectId: "chewsauce",
  storageBucket: "chewsauce.appspot.com",
  messagingSenderId: "1018018336960",
  appId: "1:1018018336960:web:a2861a91d644e936d75d25",
  measurementId: "G-N48YKQS2SK"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export default app;
export const auth = getAuth(app);
export const db = getFirestore(app);
 