

  const txoLibRdx = {

    toggleShowAll(state, action) {  
        state.showAll =action.payload.showAll; 
    },
    toggleEnterPay(state, action) {  
      state.enterPay =action.payload.enterPay; 
  }

}

export default txoLibRdx