import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";


const Cell = ({ colName, index, colIndex, children }) => {
  const id = useSelector((state) => state.units.items[index].id);

  const active = useSelector((state) => state.uo.active);
  const oddRow = useSelector((state) => state.units.items[index].shadow.odd);
  const dragName = useSelector((state) => state.uo.drag.dragName);
  const dropName = useSelector((state) => state.uo.drag.buoyName);
  const editID = useSelector((state) => state.uo.edit.id);

  const isEditing = id === editID;
  const rowChanged = useSelector(
    (state) => state.units.items[index].eChanged > 0
  );
  const colWidth = useSelector((state) => state.uo.col.widths[colName]);
  const colWidthInners = useSelector(
    (state) => state.uo.col.widthsInputs[colName]
  );
  const justData = useSelector((state) => state.uo.col.justData[colName]);

  const height = useSelector((state) => state.ui.h.line);




  const getMoneyColor = (n) => {
    if (n === 0) {
      return "text-cod-gray-900";
    } else if (n > 0) {
      return "text-malachite-900";
    } else {
      return "text-thunderbird-900";
    }
  };

  const getBGColor = () => {
    var doOddRow = [oddRow[active]] % 2 === 1;
    var isOddCol = colIndex % 2 === 1;
    if (isEditing && rowChanged) {
      return isOddCol
        ? doOddRow
          ? "bg-tabasco-500/60"
          : "bg-tabasco-500/70"
        : doOddRow
        ? "bg-tabasco-500/80"
        : "bg-tabasco-500/90";
    } else if (isEditing) {
      return isOddCol
        ? doOddRow
          ? "bg-meteor-400/60"
          : "bg-meteor-400/70"
        : doOddRow
        ? "bg-meteor-400/80"
        : "bg-meteor-400/90";
    } else if (rowChanged) {
      return isOddCol
        ? doOddRow
          ? "bg-thunderbird-400/50"
          : "bg-thunderbird-400/80"
        : doOddRow
        ? "bg-thunderbird-400/70"
        : "bg-thunderbird-400/100";
    } else {
      return isOddCol
        ? doOddRow
          ? "bg-merlin-300/80"
          : "bg-merlin-200/70"
        : doOddRow
        ? "bg-merlin-300/90"
        : "bg-merlin-200/80";
    }
  };
  return (
    <div
      className={`  whitespace-pre justify-center grow-0 shrink-0    ${colWidth} ${  height  }    `}
    >
      <div className="relative">
        <div
          className={`flex-none w-full absolute top-0 bg-blend-overlay
          ${colName === dragName && "bg-pelorous-300/30  z-30 " + height}
          ${colName === dropName && "bg-sunshade-500/30  z-30 " + height}  `}
        />

        <div
          name={colName}
          className={`flex  flex-row    whitespace-pre   z-20  justify-stretch  }`}
        >
         <div
            className={`w-0.5 flex-none whitespace-pre bg-blend-overlay  ${ height }    grow-0  shrink-0 ${
              dragName === colName
                ? " bg-pelorous-600  "
                : dropName === colName
                ? " bg-sunshade-600  "
                : getBGColor()
            }   `}
          ></div>

        <div
            className={`${colWidthInners} ${ height }   ${getBGColor()}  ${justData} whitespace-pre transition-colors duration-1000  `}
          >
              {children}
          </div>

          <div
            className={`w-0.5 flex-none  whitespace-pre  bg-blend-overlay grow-0   ${ height  }    shrink-0 ${
              dragName === colName
                ? " bg-pelorous-600  "
                : dropName === colName
                ? " bg-sunshade-600  "
                : getBGColor()
            }   `}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default Cell;
