import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
// import App from "./DnDTest/App.js";
// import DnD from './tbl/txTbl/DnD/App'
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import Store from "./store/store";
import UIFx from "./store/slices/uiSlice/uiFx";
import Tester from "./tbl/txTbl/DnD/Fx";


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Provider store={Store}> 
        <App />
        {/* <Tester /> */}
        {/* <DnDTestApp /> */}
        {/* <DnD /> */}
    </Provider>
  </BrowserRouter>
);
