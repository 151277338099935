import React from "react";
import { SysMenu } from "./SysMenu";
import { useSelector } from "react-redux";

/**
 *
 * @param {*} param0
 * @param {string} device This will change how it renders based on whether it is mobile or desktop. Although css will change, this is needed to change the options that are provided to mobile or to desktop.
 * @returns
 */
const DashBar = ({ device }) => {
  /* This is the height of the dash component. */
  const heightLogo = useSelector((state) => state.ui.h.heightLogo);

  return (
    <div className={`flex flex-row justify-start pl-5   bg-merlin-300 `}>
      <div className={`flex flex-col ${heightLogo}  justify-center`}>
        <SysMenu device={device} />{" "}
      </div>
    </div>
  );
};
export default DashBar;
