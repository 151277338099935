import { addDoc, collection, doc, getDoc, getDocs, orderBy, query, setDoc } from "firebase/firestore";
import { db } from "../../auth/fbase";
import { CHEMonth } from "../../functions/CHEDateStr";
import { RndIDKey } from "../../functions/RndIDKey";

async function readLastDate() {
  var theDoc = await getDoc(doc(db, "prefs", "enteredRent"));
  var data = theDoc.data();
  return data.last;
}

async function readUnitTxs(uID,lastRMonth,currentMonth,rentAmt) {
    const q = query(collection(db, "units", uID, "account"),orderBy('m'));
    const querySnapshot = await getDocs(q);
    var txs = [];
    querySnapshot.forEach((doc) => {
      if (doc.exists()) {
        const dD = doc.data();
        const keys = Object.keys(dD);
        if(keys.includes("r")&&(Number(dD.r)!==0)&&(Number(dD.m)>lastRMonth)){
        txs.push(Number(dD.m));}
      }
    });
    if(!txs.includes(lastRMonth)){
        txs.push(lastRMonth);
    }
    var startMonth = Math.max(txs);
    var writerList = [];
    for(var z = startMonth;z<currentMonth;z++){
        var addM = lastRMonth+1;
        var writeID = RndIDKey(20);
        var writeData = {id:writeID,m:addM,r:rentAmt}
        const locusRef = doc(db, 'units',uID,'account',writeID);
        writerList.push(setDoc(locusRef, writeData, { merge: true }));
    }
    Promise.all(writerList).then(()=>{});
    return uID;
}

async function readAllUnits(lastMRentEntered) {
  var theDocs = await getDocs(collection(db, "units"));
  var maxMonth = CHEMonth;
  var waitingList = [];
  theDocs.forEach((doc) => {
    var data = doc.data();
    if (data.active === 1) {
      // then get last rent month within its data;
      var rent = Number(data.rent);
      waitingList.push(readUnitTxs(doc.id,lastMRentEntered,maxMonth,rent));

    }
  });
  return await Promise.all(waitingList);
}

const UpdateRent = () => {
  readLastDate().then((r) => {
    readAllUnits(r).then((uu) => {
        console.log(uu);
    });
  });
};

export default UpdateRent;
