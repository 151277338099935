import React from "react";
import { useDroppable } from "@dnd-kit/core";

export function DroppableBooths(props) {
  const { isOver, setNodeRef } = useDroppable({
    id: props.id,
  });

  return (
    <div
      ref={setNodeRef}
      className={`${
        isOver ? "bg-denim-800" : props.isPay ? "bg-bilbao-600" : "bg-lotus-700"
      } flex flex-col      z-12      w-72 h-fit`}
    >
      {props.children}
    </div>
  );
}
