import { RndKey } from "../../../functions/RndKey";
import DeepCopyTree from "../../cloudFx/DeepCopyTree";
import txRibInit from "./init";
import { fxPopulateLoopData } from "./rdxFxs";

const txRibRdx = {
  setUnitID(state, action) {
    state.unitID = action.payload.id;
  },
  setShadowGUI(state,action){
    state.shadowGUI={...action.payload.data.data};
  },
  addPayTx(state, action) {
    let tx = action.payload.tx;

    let payIDs = [...state.payments.ids];
    let rosterMonths = DeepCopyTree(state.payments.roster.months);
    let rosterMIDs = DeepCopyTree(state.payments.roster.mIDs);
    let month = state.months.xRef[tx.m];

    if (!rosterMIDs?.includes(month)) {
      rosterMIDs.push(month);
    }

    if (!Object.keys(rosterMonths)?.includes(month)) {
      rosterMonths[month] = [];
    }
    rosterMonths[month].push(tx.id);
    payIDs.push(tx.id);
    state.payments.ids = payIDs;
    state.payments.roster.mIDs = rosterMIDs;
    state.payments.roster.months = rosterMonths;
    let monthsKids = DeepCopyTree(state.months.children[month], "array");
    if (!monthsKids?.includes(tx.id)) {
      monthsKids.push(tx.id);
    }
    let tempMTx = {};
    tempMTx[tx.id] = month;
    state.months.children = { ...state.months.children, ...tempMTx };
    state.months.children[month] = monthsKids;
  },
  addChgTx(state, action) {
    let tx = action.payload.tx;

    let chgIDs = [...state.charges.ids];
    let rosterMonths = DeepCopyTree(state.charges.roster.months);
    let rosterMIDs = DeepCopyTree(state.charges.roster.mIDs);
    let month = state.months.xRef[tx.m];

    if (!rosterMIDs?.includes(month)) {
      rosterMIDs.push(month);
    }

    if (!Object.keys(rosterMonths)?.includes(month)) {
      rosterMonths[month] = [];
    }
    rosterMonths[month].push(tx.id);
    chgIDs.push(tx.id);
    state.charges.ids = chgIDs;
    state.charges.roster.mIDs = rosterMIDs;
    state.charges.roster.months = rosterMonths;
    let monthsKids = DeepCopyTree(state.months.children[month], "array");
    if (!monthsKids?.includes(tx.id)) {
      monthsKids.push(tx.id);
    }
    let tempMTx = {};
    tempMTx[tx.id] = month;
    state.months.children = { ...state.months.children, ...tempMTx };
    state.months.children[month] = monthsKids;
  },
  addBounceTx(state, action) {
    let tx = action.payload.tx;

    let bounceIDs = [...state.bounces.ids];
    let rosterMonths = DeepCopyTree(state.bounces.roster.months);
    let rosterMIDs = DeepCopyTree(state.bounces.roster.mIDs);
    let month = state.months.xRef[tx.m];

    if (!rosterMIDs?.includes(month)) {
      rosterMIDs.push(month);
    }

    if (!Object.keys(rosterMonths)?.includes(month)) {
      rosterMonths[month] = [];
    }
    rosterMonths[month].push(tx.id);
    bounceIDs.push(tx.id);
    state.bounces.ids = bounceIDs;
    state.bounces.roster.mIDs = rosterMIDs;
    state.bounces.roster.months = rosterMonths;
    let monthsKids = DeepCopyTree(state.months.children[month], "array");
    if (!monthsKids?.includes(tx.id)) {
      monthsKids.push(tx.id);
    }
    let tempMTx = {};
    tempMTx[tx.id] = month;
    state.months.children = { ...state.months.children, ...tempMTx };

    state.months.children[month] = monthsKids;
  },
  addTxs(state, action) {
    let tempTxs = {};
    let theTxs = action.payload.txs;
    Object.keys(theTxs).forEach((k) => {
      tempTxs[theTxs[k].dataTx.id] = theTxs[k].dataTx;
    });

    state.txs = tempTxs;
  },
  setMonths(state, action) {
    let mRaw = action.payload.months;
    mRaw.sort((a,b)=>{return Number(a)-Number(b)});
    state.unitID = action.payload.uID;

    let dummy = txRibInit();
    state.txs = dummy.txs;
    state.months = dummy.months;
    state.draggingID = dummy.draggingID;
    state.tickets = dummy.tickets;
    state.all = dummy.all;
    state.startBal = dummy.startBal;
    state.payments = dummy.payments;
    state.charges = dummy.charges;
    state.bounces = dummy.bounces;
    state.gui = dummy.gui;

    let monthNums = [];
    let monthIDs = [];
    let kids = {};
    let xRef = {};
    mRaw.forEach((m) => {
      let mStr = m + "";
      monthNums.push(Number(m));
      let tempID = RndKey();
      monthIDs.push(tempID);
      kids[tempID] = [];
      xRef[mStr] = tempID;
      xRef[tempID] = m;
    });

    state.months.ids = monthIDs;
    state.months.num = monthNums;
    state.months.children = kids;
    state.months.xRef = xRef;
  },
  setInitGUI(state) {
    let monthIDs = DeepCopyTree(state.months.ids);
    const monthChildren = DeepCopyTree(state.months.children);
    const xRefMonth = state.months.xRef;
    const txs = state.txs;

    function compTxAB(a, b) {
      return txs[b].priority - txs[a].priority;
    }

    var ticketValues = {};
    var location = {};
    var amounts = {};
    var dir = {};
    var xrefAmt = {};
    monthIDs.forEach((mID) => {
      let monthTxs = DeepCopyTree(monthChildren[mID]);
      monthTxs.sort(compTxAB);
      monthChildren[mID] = monthTxs;
      monthTxs.forEach((mTxID) => {
        let mTx = txs[mTxID];
        ticketValues[mTxID] = [undefined, undefined];
        location[mTxID] = [undefined, undefined];
        dir[mTxID] = mTx.m;
        xrefAmt[mTxID] = [0, 0];
        if (mTx.both === 3) {
          ticketValues[mTxID][0] = {
            uID:state.unitID,
            id: mTxID,
            amt: Number(mTx.paySum),
            rNum: mTx.rNum,
            type: "pay",
          };
          ticketValues[mTxID][1] = {
            uID:state.unitID,
            id: mTxID,
            amt:Number(mTx.chgSum),
            rNum:  mTx.rNum,
            type: "chg",
          };
          if (!Object.keys(amounts).includes(mID)) {
            amounts[mID] = [0, 0];
          }
          location[mTxID][0] = mID + "-bot";
          location[mTxID][1] = mID + "-bot";
          amounts[mID][0] += Number(mTx.paySum);
          amounts[mID][1] += Number(mTx.chgSum);
          xrefAmt[mTxID][0] = Number(mTx.paySum);
          xrefAmt[mTxID][1] = Number(mTx.chgSum);
        } else if (mTx.both === 1) {
          ticketValues[mTxID][0] = {
            uID:state.unitID,
            id: mTxID,
            amt:Number(mTx.paySum),
            rNum: mTx.rNum,
            type: "pay",
          };
          location[mTxID][0] = mID + "-pay";
          xrefAmt[mTxID][0] = Number(mTx.paySum);
          if (!Object.keys(amounts).includes(mID)) {
            amounts[mID] = [0, 0];
          }
          amounts[mID][0] += Number(mTx.paySum);
        } else if (mTx.both === 2) {
          ticketValues[mTxID][1] = {
            uID:state.unitID,
            id: mTxID,
            amt: Number(mTx.chgSum),
            rNum: mTx.rNum,
            type: "chg",
          };
          location[mTxID][1] = mID + "-chg";
          xrefAmt[mTxID][1] = Number(mTx.chgSum );
          if (!Object.keys(amounts).includes(mID)) {
            amounts[mID] = [0, 0];
          }
          amounts[mID][1] += Number(mTx.chgSum );
          // }
        } else {
        }
      });
    });
    state.months.children = monthChildren;
    state.gui.directory = dir;
    state.gui.amounts = amounts;
    state.gui.values = ticketValues;
    state.gui.valueIDs=Object.keys(ticketValues);
    state.gui.location = location;
    state.gui.xRefAmounts = xrefAmt;
  },
  moveIndex(state, action) {
    let monthID = action.payload.monthID;
    let type = action.payload.type;
    let toId = action.payload.toId;
    let fromId= action.payload.fromId;

     var dict = {};//data.locus[monthID][type];
    var entries = [];
    Object.keys(dict).forEach((k) => {
      entries.push(k);
    });

    var c = entries.indexOf(fromId);
    entries = [...entries.slice(0, c), ...entries.slice(c + 1)];
    var d = entries.indexOf(toId);
    entries = [...entries.slice(0, d), fromId, ...entries.slice(d)];
    var dupDict = {};
    entries.forEach((e) => {
      dupDict[e] = dict[e];
    });

    // updateData((core) => {
    //   core["locus"][monthID][type] = dupDict;
    // });
  },


  guiClear(state, action) {
    state.gui = {
      location: {},
      directory: {},
      amounts: {},
      xRefAmounts: {},
      values: {},
    };
  },
  guiSetValues(state, action) {
    var data = action.payload.data;
    state.gui.values = data;
  },

};

export default txRibRdx;
