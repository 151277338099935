import { useContext, useEffect, createContext } from "react";

import { AuthCtx } from "../../auth/Context";
import { Navigate } from "react-router-dom";

import DashBar from "./DashBar";


import { useSelector } from "react-redux";
import LoadStore from "../../store/loadStore";
import FocusScreen from "../../tbl/FocusScreen";
export const DashboardCtx = createContext();

export default function Dashboard({ device }) {
  const { signedIn } = useContext(AuthCtx);

  const textSize = useSelector((state) => state.ui.textSize);

  useEffect(() => {}, [signedIn]);
  return (
    <div className="">
      {/* <div className=" bg-bilbao-200">Dashboard Head</div> */}

      <div className="m-10">
        {!signedIn ? (
          <div>
            <Navigate to="/" />
            <div className="text-xl ">LOADING in Dashboard</div>
          </div>
        ) : (
          <LoadStore device={device}>
            {/* select-none prevents the selection of the text, especially when tapping on mobile screen */}
            {/* this is the main box for everything. On non-mobile it is centered on screen  */}
            {/* set up as ROW so L to R children ... that allows for two col-boxes on larger screen or one col-box on mobile  */}
            <div
              className={`flex flex-row select-none justify-center ${textSize}`}
            >
              {/*  lg:mt-44 causes it to drop box on the screen  */}
              <div className="flex flex-col lg:mt-44 lg:justify-center">
                {/* this is the main column. on mobile it is just one column. on larger might have more than one column  */}
                <div className="flex flex-col justify-center">
                  {/* This is the menu/dashbar on top of the screen with logo for log-out */}
                  <DashBar device={device} />

                  {/* This is the main display for all size screens*/}
                  <FocusScreen className="flex " device={device} />
                </div>
              </div>
            </div>
          </LoadStore>
        )}
      </div>
      {/* <div className='bg-salem-200 text-cod-gray-900'>
        Dashboard Tail</div> */}
    </div>
  );
}
