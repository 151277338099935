import React, { useContext, useEffect    } from 'react'

import RentBox from './RentBox'
import { AsMoneyShort } from '../../../../functions/AsMoney'
import { useSelector } from 'react-redux'

const Rent = ({index} ) => {


  const rent = useSelector((state) => state.units.items[index].shadow.rent);
  const id = useSelector((state) => state.units.items[index].id);
  const editID = useSelector((state) => state.uo.edit.id);
  const isEditing = editID===id;
  const height = useSelector((state) => state.ui.h.line);
  const justData = useSelector((state) => state.uo.col.justData['name']);
  const colWidth = useSelector((state) => state.uo.col.widths.rent);
  const colWidthInner = useSelector((state) => state.uo.col.widthsInputs.rent);

  useEffect(()=>{

  },[])


  return (
    <div className={`flex flex-row  ${height}  ${colWidthInner} ${justData} `} >
        <div className={` ${isEditing?'invisible w-0':'visible'} `}>{ AsMoneyShort(rent, false, 9)} </div>
        <div className={` ${isEditing?'visible':'hidden'} text-crimson-900`}><RentBox index={index} /></div>

    </div>
  )
}

export default Rent







