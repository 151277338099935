
import React from 'react'

const FloppyICO = ({grayOut=true}) => {

	const colorA="#0000DD"
	// main disk material

	const colorD="#000000"
	// exterior disk color
	const colorF=grayOut?"#AAAAFF":"#AAAAAA";

  return (
	<div className='w-7 h-7 flex flex-row justify-center'>


<svg
	 viewBox="0 0 24 24"  >
<g>


		<path style={{fill:colorA}} d="M1.8,19.5c-0.7,0-1.3-0.6-1.3-1.3V1.8c0-0.7,0.6-1.3,1.3-1.3h14l3.7,3.7v14c0,0.7-0.6,1.3-1.3,1.3H1.8z"/>
			<path style={{fill:colorD}} d="M15.6,1L19,4.4v13.8c0,0.4-0.4,0.8-0.8,0.8H1.8C1.4,19,1,18.6,1,18.2V1.8C1,1.4,1.4,1,1.8,1H15.6 M16,0H1.8
		C0.8,0,0,0.8,0,1.8v16.4c0,1,0.8,1.8,1.8,1.8h16.4c1,0,1.8-0.8,1.8-1.8V4L16,0L16,0z"/>

<path style={{fill:colorF}} d="M 10.5,3.2 15,8.1 12.8,8.1 12.8,16.8 8.3,16.8 8.3,8.1 6.1,8.1 Z "/>


</g>
</svg>

</div>

)
}

export default FloppyICO