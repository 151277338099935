const srcDigits = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
const srcDigitsLen = srcDigits.length;

function getChopped(n){
    var key = "";

    while(key.length<n){
         var t = srcDigits[Math.floor(Math.random()*srcDigitsLen)]
         if(t){key+=t;}
    }

    return key;
}


export function RndIDKey(len=20){
    return getChopped(len);
}