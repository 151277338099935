
import React, { useContext, useEffect, useState } from 'react'

import UnitTblActions from '../../../../store/slices/uoSlice/uoFxs';
import EChange from '../../../../store/statics/EChange';
import { useDispatch, useSelector } from 'react-redux';

const RentBox = ({ index }) => {

  const [rentStr, setRent] = useState(" ");

  const rent = useSelector((state) => state.units.items[index].shadow.rent);
  const id = useSelector((state) => state.units.items[index].id);
  const eChanged = useSelector((state) => state.units.items[index].eChanged);
  const colWidth = useSelector((state) => state.uo.col.widthsInputs.rent);
  const dispatch = useDispatch();
  const reportRentChange = (e) => {
    var screen = e.target.value.match(/^\s{0,}(?<rent>\d{0,})\s{0,}/);
    setRent(()=>{return screen.match});
  }

  const reportRentChanged = (e) => {
    var screen = e.target.value.match(/^\s{0,}(?<neg>-){0,1}\s{0,}(?<dollars>\d{0,})(?<dot>\.){0,1}(?<cents>\d\d){0,1}\s{0,}/);
    // console.log(screen.groups?.dollars+screen.groups?.dot+ screen.groups?.cents);
    if (screen.groups.dollars) {
      setRent(() => { return (screen.groups?.neg ? -1 : 1) * Number(screen.groups.dollars + (screen.groups?.dot ? '.' : '') + (screen.groups?.cents ? screen.groups.cents : '')); })
      var tt = {};
      tt['rent'] = ((screen.groups?.neg ? -1 : 1) * Number(screen.groups.dollars + (screen.groups?.dot ? '.' : '') + (screen.groups?.cents ? screen.groups.cents : '')));


      var eC =  eChanged;

      eC |= EChange.rent;

      if (tt['rent'] === rent) {
        eC ^= EChange.rent;
      }
      setRent(() => { return tt.rent })
      dispatch(UnitTblActions.editPartAction()( id, index, tt, eC));
    }
  }
 
  useEffect(() => {

    setRent(() => { return  rent });

  }, [rent])

  return (
    <div className='ml-1.5' >
      <input type='text' value={rentStr} className={`${colWidth}  bg-tabasco-200/40 text-right   lg:pr-8`} onChange={(e) => { reportRentChange(e) }} onBlur={(e) => { reportRentChanged(e) }} />
    </div>
  )
}
export default RentBox