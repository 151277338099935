import React, { useContext, useEffect } from "react"; 
 
import { useSelector } from "react-redux";
import GetCHEDateStr from "../../../../functions/CHEDateStr";

const EndRMonth = ({ index }) => { 

  const id = useSelector((state) => state.units.items[index].id);
  const endRMonth = useSelector(
    (state) => state.units.items[index].shadow.endRMonth
  );

  const colWidth = useSelector((state) => state.uo.col.widths.endRMonth);
  const justCol = useSelector((state) => state.uo.col.justData.endRMonth);
  const edit = useSelector((state) => state.uo.edit.id);

  const h = useSelector((state) => state.ui.h);

  useEffect(() => {}, []);

  return (
    <div
      className={`flex-none relative ${justCol} shrink ${h.line} text-center  ${ endRMonth} ${colWidth} `}
    > 
        {GetCHEDateStr(endRMonth)}
    </div>
  );
};

export default EndRMonth;
