import React from 'react'

const Desktop = ({className}) => {
  return (
    <div className={className}>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="32" viewBox="0 0 24 24" strokeWidth="1" stroke="#2c3e50" fill="none" strokeLinecap="round" strokeLinejoin="round">


  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <path d="M3 5a1 1 0 0 1 1 -1h16a1 1 0 0 1 1 1v10a1 1 0 0 1 -1 1h-16a1 1 0 0 1 -1 -1v-10z" />
  <path d="M7 20h10" />
  <path d="M9 16v4" />
  <path d="M15 16v4" />


        </svg></div>
    )


}

export default Desktop

