import React from 'react'

const RightArrowIcon = ({className}) => {
  return (
    <div className={className}>
      <svg xmlns="http://www.w3.org/2000/svg"   width="24" height="32" viewBox="0 0 24 24" strokeWidth="1" stroke="#2c3e50" fill="none" strokeLinecap="round" strokeLinejoin="round">


      <path
     stroke="none"
     d="M -2.4144271,-3.4104208 H 21.585573 V 20.589579 H -2.4144271 Z"
     fill="none"
     id="path34" />
  <path
     d="m 12.414001,0.58957923 6.586,6.58599997 a -2,2 0 0 1 0,2.8279998 l -6.586,6.586 a -2,2 0 0 1 -2.18,0.434 l -0.145,-0.068 a -2,2 0 0 1 -1.0890004,-1.78 v -2.586 h -7 a -2,2 0 0 1 -1.9999999975295,-2 V 6.5895792 l 0.00499999753,-0.15 a -2,2 0 0 1 1.995,-1.85 l 7,-0.001 v -2.585 A -2,2 0 0 1 12.414001,0.58957923 Z"
     strokeWidth="0"
     fill="currentColor"
     id="path36" />

        </svg></div>
    )


}

export default RightArrowIcon

